import React, { FunctionComponent, useState } from 'react';
import { Box, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { InputBase } from 'formik-material-ui';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  setIsSignupModalOpen,
  updateUsername,
  useEditor,
  useShouldOpenSignupModal,
} from '../../../store';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { handleErrors } from '../../../utils/helpers';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import { Hidden } from '../../ui/Hidden';

const useStyles = makeStyles((theme) => ({
  adornment: {
    marginRight: 0,
    marginLeft: 5,
  },
  root: {
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    minWidth: '100%',
    margin: '0 auto',
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.grey[600],
    marginLeft: 5,
  },
  iconContainer: {
    display: 'flex',
  },
  urlText: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'block',
      marginLeft: 0,
      wordBreak: 'break-all',
    },
  },
}));

const UsernameUpdate: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const user = useEditor((editor) => editor.user);
  const shouldOpenSignupModal = useShouldOpenSignupModal();
  const [editing, setEditing] = useState(false);

  const url = `${global.location.origin}/${user.username}`;

  const handleSubmit = async ({ username }, actions) => {
    if (username === user.username || !username) {
      setEditing(false);
      return;
    }

    try {
      if (shouldOpenSignupModal) {
        return await dispatch(setIsSignupModalOpen(true));
      }

      await axios.put('/api/users/current/username', { username });
      toast.success(`Succesfully updated username to "${username}"`);
      dispatch(updateUsername(username));
      setEditing(false);
    } catch (err) {
      handleErrors(err, "Couldn't update username, please try again later");
    }
  };

  if (editing)
    return (
      <>
        <Formik initialValues={{ username: user.username }} onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <Form>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Field
                  component={InputBase}
                  name="username"
                  placeholder="yournamehere"
                  fullWidth
                  startAdornment={
                    <>
                      <Hidden down="sm">
                        <Typography style={{ minInlineSize: 'max-content' }} display="inline">
                          My BrandLink:
                        </Typography>
                      </Hidden>
                      <InputAdornment className={classes.adornment} position="start">
                        brandl.ink/
                      </InputAdornment>
                    </>
                  }
                  inputProps={{ style: { padding: 0 } }}
                />
                <span className={classes.iconContainer}>
                  <IconButton onClick={() => setEditing(false)} size="small">
                    <ClearIcon />
                  </IconButton>
                  <IconButton type="submit" size="small">
                    <DoneIcon />
                  </IconButton>
                </span>
              </Box>
            </Form>
          )}
        </Formik>
      </>
    );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {user.username ? (
        <Typography display="inline">
          My BrandLink:
          <a
            className={clsx(classes.link, classes.urlText)}
            href={url}
            rel="noreferrer"
            target="_blank"
          >
            {url}
          </a>
        </Typography>
      ) : (
        <Typography display="inline">
          <>Pick a username!</>
        </Typography>
      )}
      <IconButton size="small" onClick={() => setEditing(true)}>
        <EditIcon />
      </IconButton>
    </Box>
  );
};

export { UsernameUpdate };
