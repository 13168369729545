import { makeStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Cropper from 'react-cropper';
import Box from '@material-ui/core/Box';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { editUserFields, setIsImageUploadModalOpen, useEditor } from '../../../store';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
  },
  dialog: {
    padding: 20,
  },
}));
const ImagePickerModal: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isImageUploadModalOpen = useEditor((editor) => editor.isImageUploadModalOpen);
  const [cropper, setCropper] = useState<Cropper | undefined>();
  const [image, setImage] = useState(undefined);
  const [submitting, setSubmitting] = useState(false);

  const onClose = () => {
    dispatch(setIsImageUploadModalOpen(false));
  };

  // reset image upon opening
  useEffect(() => {
    if (isImageUploadModalOpen) {
      setImage(undefined);
    }
  }, [isImageUploadModalOpen]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as any);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
    maxFiles: 1,
    multiple: false,
  });

  const onUpload = async () => {
    if (cropper && cropper.getCroppedCanvas()) {
      setSubmitting(true);
      try {
        await dispatch(editUserFields({ avatar: cropper.getCroppedCanvas().toDataURL() }));
      } finally {
        setSubmitting(false);
        handleClose();
      }
    }
  };

  const handleClose = () => {
    if (cropper) cropper.clear();
    onClose();
  };

  return (
    <Dialog open={isImageUploadModalOpen} onClose={handleClose}>
      <div className={classes.dialog}>
        {!image ? (
          <>
            <Typography variant="h5" component="h2">
              Select an image
            </Typography>
            <section>
              <div {...getRootProps()} className={classes.dropzone}>
                <input {...getInputProps()} />
                <p>Drag and drop an image file here or click</p>
                <CloudUploadIcon fontSize="large" />
              </div>
            </section>
          </>
        ) : (
          <>
            <Box sx={{ pt: 2 }}>
              <Cropper
                src={image}
                style={{ width: '100%' }}
                aspectRatio={1}
                guides={false}
                viewMode={3}
                autoCropArea={1}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
              />
            </Box>
            <Box sx={{ textAlign: 'right', mt: 2 }}>
              <LoadingButton
                loading={submitting}
                sx={{ mr: 1 }}
                variant="contained"
                color="primary"
                onClick={onUpload}
              >
                Upload
              </LoadingButton>
              <LoadingButton variant="contained" color="secondary" onClick={handleClose}>
                Cancel
              </LoadingButton>
            </Box>
          </>
        )}
      </div>
    </Dialog>
  );
};

export { ImagePickerModal };
