import React, { FunctionComponent } from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useAppDispatch } from '../../hooks/useAppStore';
import {
  reorderBlocks,
  reorderSocialsForSocialsBlock,
  setIsAddBlockModalOpen,
  useEditor,
} from '../../store/';
import { BLUE_STYLE, Styles } from '@brandlink/models';
import AddIcon from '@material-ui/icons/Add';
import { DraggableBlock } from './DraggableBlock';
import { equals, isEmpty } from 'ramda';
import { AvatarCard } from '../Admin/AvatarCard/AvatarCard';
import { Theme } from '@material-ui/core/styles';
import { getBlockIndexFromDroppableId } from '../../utils/helpers';
import { useDndPlaceholder } from '../../hooks';
import { Placeholder } from './Placeholder';

const useStyles = makeStyles<Theme, Styles>((theme) => ({
  droppable: {
    boxSizing: 'border-box',
    minHeight: '100%',
    position: 'relative',
    padding: '8px',
    paddingTop: '35px',
    width: '100%',
    maxWidth: '700px',
  },
  root: ({ background }) => ({
    background: background?.style,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    minHeight: '100%',
  }),
}));

export interface DndEditorProps {
  scale?: number;
}

const DndEditor: FunctionComponent<DndEditorProps> = ({ scale = 1 }) => {
  const styles = useEditor((editor) => editor.styles);
  const classes = useStyles(isEmpty(styles ?? {}) ? BLUE_STYLE : styles!);
  const dispatch = useAppDispatch();
  const preview = useEditor((editor) => editor.preview);

  const blocks = useEditor(
    (editor) =>
      editor.blocks.map(({ _id, draggableId }) => ({
        _id,
        draggableId,
      })),
    equals
  );

  const { onBeforeDragStart, resetPlaceholder } = useDndPlaceholder();

  const onDragEnd = (result: DropResult) => {
    resetPlaceholder();
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    switch (result.type) {
      case 'dnd-editor':
        dispatch(
          reorderBlocks({ source: result.source.index, destination: result.destination.index })
        );
        break;
      case 'socials':
        dispatch(
          reorderSocialsForSocialsBlock({
            blockIndex: getBlockIndexFromDroppableId(result.destination.droppableId),
            source: result.source.index,
            destination: result.destination.index,
          })
        );
        break;
      default:
        console.warn('Droppable type case not implemented');
    }
  };

  const handleAdd = () => {
    dispatch(setIsAddBlockModalOpen({ open: true }));
  };

  return (
    <div className={classes.root}>
      <DragDropContext onBeforeDragStart={onBeforeDragStart} onDragEnd={onDragEnd}>
        <Droppable
          type="dnd-editor"
          droppableId="dnd-editor"
          renderClone={
            scale === 1
              ? undefined
              : (provided, snapshot, rubric) => (
                  <DraggableBlock
                    blockIndex={rubric.source.index}
                    scale={scale}
                    provided={provided}
                    snapshot={snapshot}
                    rubric={rubric}
                  />
                )
          }
        >
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className={classes.droppable}>
              <AvatarCard />
              {blocks.map((block, blockIndex) => (
                <Draggable
                  key={block._id || block.draggableId}
                  draggableId={block._id || block.draggableId!}
                  index={blockIndex}
                >
                  {(provided, snapshot, rubric) => (
                    <DraggableBlock
                      blockIndex={blockIndex}
                      scale={scale}
                      provided={provided}
                      snapshot={snapshot}
                      rubric={rubric}
                    />
                  )}
                </Draggable>
              ))}
              <Placeholder droppableId="dnd-editor" placeholder={provided.placeholder} />
              {!preview && (
                <div
                  style={{
                    background: 'white',
                    borderRadius: '50%',
                    width: 'fit-content',
                    margin: '0 auto',
                  }}
                >
                  <IconButton onClick={handleAdd} color="primary">
                    <AddIcon />
                  </IconButton>
                </div>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export { DndEditor };
