import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { useAppStore } from '../hooks/useAppStore';
import { BlockDefinition, Styles } from '@brandlink/models';

export interface ProfileSliceState {
  _id: string;
  blocks: BlockDefinition[];
  styles?: Styles;
  loading: boolean;
  notFound: boolean;
  username: string;
  bio: string;
  title: string;
  avatar: string;
  background: string;
}

export interface ProfileResponse {
  _id: string;
  blocks: BlockDefinition[];
  styles?: Styles;
  username: string;
  bio: string;
  title: string;
  avatar: string;
  background: string;
}

export const getProfile = createAsyncThunk<
  ProfileResponse,
  string,
  {
    rejectValue: number;
  }
>('profile/getProfile', async (username, { rejectWithValue }) => {
  try {
    const { data } = await axios.get<ProfileResponse>(`/api/users/${username}/profile`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    const status = error.response.status;
    return rejectWithValue(status as number);
  }
});

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    _id: '',
    blocks: [],
    styles: undefined,
    loading: true,
    notFound: false,
    username: '',
    bio: '',
    title: '',
    avatar: '',
    background: '',
  } as ProfileSliceState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          loading: false,
          notFound: false,
        };
      })
      .addCase(getProfile.rejected, (state, action) => {
        const notFound = action.payload === 404;
        return {
          ...state,
          loading: false,
          notFound,
        };
      });
  },
});

// export const { setUser, setAuthChecked } = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
export const useProfile = () => useAppStore((state) => state.profile);
