import { BlockType, LinkBlockDefinition } from '@brandlink/models';
import { LinkBlock } from '../components/Blocks/LinkBlock/LinkBlock';
import { LinkBlockEditor } from '../components/Blocks/LinkBlock/LinkBlockEditor';
import { VideoBlock } from '../components/Blocks/VideoBlock/VideoBlock';
import { VideoBlockEditor } from '../components/Blocks/VideoBlock/VideoBlockEditor';
import LinkIcon from '@material-ui/icons/Link';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import { FunctionComponent, ReactNode } from 'react';
import { LinkBlockVisualEditor } from '../components/Blocks/LinkBlock/LinkBlockVisualEditor';
import { VideoBlockVisualEditor } from '../components/Blocks/VideoBlock/VideoBlockVisualEditor';
import ShareIcon from '@material-ui/icons/Share';
import SubjectIcon from '@material-ui/icons/Subject';
import { TextBlock } from '../components/Blocks/TextBlock/TextBlock';
import { TextBlockEditor } from '../components/Blocks/TextBlock/TextBlockEditor';
import { TextBlockVisualEditor } from '../components/Blocks/TextBlock/TextBlockVisualEditor';
import { SocialsBlock } from '../components/Blocks/SocialsBlock/SocialsBlock';
import { SocialsBlockEditor } from '../components/Blocks/SocialsBlock/SocialsBlockEditor';
import { SocialsBlockVisualEditor } from '../components/Blocks/SocialsBlock/SocialsBlockVisualEditor';
import axios from 'axios';

export interface BlockInfo {
  name: string;
  icon: ReactNode;
  mainComponent: FunctionComponent<any>;
  editorComponent: FunctionComponent<any>;
  visualEditorComponent: FunctionComponent<any>;
  defaultBlock: Record<string, any>;
  createHandleAnalyticsEvent?: (userId: string, block: any) => () => void;
}

export const blockConfig: Record<number, BlockInfo> = {
  [BlockType.Link]: {
    name: 'Link',
    icon: LinkIcon,
    mainComponent: LinkBlock,
    editorComponent: LinkBlockEditor,
    visualEditorComponent: LinkBlockVisualEditor,
    defaultBlock: {
      type: BlockType.Link,
      title: '',
      url: '',
    },
    createHandleAnalyticsEvent: (userId: string, block: LinkBlockDefinition) => () => {
      axios.post(`/api/analytics/${userId}/${block._id}/click`); // fire and forget
    },
  },
  [BlockType.Video]: {
    name: 'Video',
    icon: VideoLibraryIcon,
    mainComponent: VideoBlock,
    editorComponent: VideoBlockEditor,
    visualEditorComponent: VideoBlockVisualEditor,
    defaultBlock: {
      type: BlockType.Video,
      url: '',
    },
  },
  [BlockType.Socials]: {
    name: 'Socials',
    icon: ShareIcon,
    mainComponent: SocialsBlock,
    editorComponent: SocialsBlockEditor,
    visualEditorComponent: SocialsBlockVisualEditor,
    defaultBlock: {
      type: BlockType.Socials,
      socials: [],
    },
  },
  [BlockType.Text]: {
    name: 'Text',
    icon: SubjectIcon,
    mainComponent: TextBlock,
    editorComponent: TextBlockEditor,
    visualEditorComponent: TextBlockVisualEditor,
    defaultBlock: {
      type: BlockType.Text,
      text: '',
    },
  },
};
