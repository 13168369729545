import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';
import { AdminDependencies } from '../components/Admin/AdminDependencies';
import { DndEditor } from '../components/DndEditor/DndEditor';

const useStyles = makeStyles((theme) => ({
  dndEditor: {
    height: '100%',
    width: '100%',
  },
}));

export const MobileVisualEditor: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.dndEditor}>
        <DndEditor />
      </div>
      <AdminDependencies mobile={true} />
    </>
  );
};
