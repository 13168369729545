import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import { BLUE_STYLE, SocialsBlockDefinition, Styles } from '@brandlink/models';
import { useEditor } from '../../../store/editorSlice';
import Paper from '@material-ui/core/Paper';
import { SocialsBlock } from './SocialsBlock';
import { SocialsBlockEditor } from './SocialsBlockEditor';
import { isEmpty } from 'ramda';

const useStyles = makeStyles<Theme, Styles>((theme) => ({
  container: {},
  dragging: {},
  editing: {},
  emptyContainer: {
    padding: '17px 8px',
    textAlign: 'center',
  },
}));

export interface SocialsBlockEditorProps {
  block: SocialsBlockDefinition;
  isDragging: boolean;
  blockIndex: number;
  editing: boolean;
}

const SocialsBlockVisualEditor: FunctionComponent<SocialsBlockEditorProps> = React.memo(
  ({ block, isDragging, blockIndex, editing }) => {
    const styles = useEditor((editor) => editor.styles);
    const classes = useStyles(isEmpty(styles ?? {}) ? BLUE_STYLE : styles!);

    return (
      <div
        className={clsx(
          classes.container,
          isDragging && classes.dragging,
          editing && classes.editing
        )}
      >
        {editing ? (
          <Paper className={classes.emptyContainer} elevation={0}>
            <SocialsBlockEditor blockIndex={blockIndex} inVisualEditor />
          </Paper>
        ) : Object.keys(block.socials).length ? (
          <SocialsBlock inVisualEditor block={block} styles={styles} />
        ) : (
          <Paper className={classes.emptyContainer} elevation={0}>
            No Social Icons, double click to start editing!
          </Paper>
        )}
      </div>
    );
  }
);

export { SocialsBlockVisualEditor };
