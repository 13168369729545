import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';
import { PhoneEditor } from '../components/PhoneEditor/PhoneEditor';
import { Hidden } from '../components/ui/Hidden';
import { AdminMobileTabsControl } from '../components/Admin/Tabs/AdminMobileTabsControl';
import { MobileChangeButtons } from '../components/Admin/MobileComponents/MobileChangeButtons';
import { AdminDependencies } from '../components/Admin/AdminDependencies';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { MobileVisualEditor } from './MobileVisualEditor';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    margin: 0,
  },
  phoneContainer: {
    height: '100%',
    overflow: 'hidden',
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  fullHeight: {
    height: '100%',
  },
}));

const ProfileLayout: FunctionComponent = ({ children }) => {
  const classes = useStyles();

  const history = useHistory();

  let match = useRouteMatch();

  if (`${match.path}/visual-editor` === history.location.pathname) {
    return <MobileVisualEditor />;
  }

  return (
    <Grid container className={classes.container}>
      <Hidden up="md">
        <MobileChangeButtons />
      </Hidden>
      <Hidden down="md">
        <Grid item xs={12} md={4} className={classes.phoneContainer}>
          <PhoneEditor />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={8} className={classes.fullHeight}>
        {children}
      </Grid>
      <AdminDependencies />
      <Hidden up="md">
        <AdminMobileTabsControl />
      </Hidden>
    </Grid>
  );
};

export default ProfileLayout;
