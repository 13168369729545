"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ANNIE_STYLES = exports.LITTLE_LEAF_STYLE = exports.SUZY_STYLE = exports.DARK_SKIES_STYLE = exports.DEEP_SPACE_STYLE = exports.SWEET_MORNING_STYLE = exports.BLUE_STYLE = void 0;
const styles_1 = require("./styles");
// export const TEXT_MAIN = {
//   color: 'black',
//   background: 'white',
//   boxShadow:
//     'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
//   borderRadius: '2px',
// };
exports.BLUE_STYLE = {
    background: {
        type: styles_1.BackgroundType.Style,
        style: 'linear-gradient(150deg, rgb(118, 243, 250), rgb(58, 78, 255))',
    },
    link: {
        main: {
            border: '2px solid #fff',
            color: 'white',
            borderRadius: '2px',
            background: 'initial',
        },
        hover: {
            background: '#fff',
            color: 'rgb(58, 78, 255)',
        },
    },
    text: {
        main: {
            color: 'white',
            background: 'rgb(78 132 253)',
            // boxShadow:
            //   'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
            borderRadius: '2px',
        },
    },
};
exports.SWEET_MORNING_STYLE = {
    background: {
        type: styles_1.BackgroundType.Style,
        style: 'linear-gradient(to top, #ff5f6d, #ffc371)',
    },
    link: {
        main: {
            border: '2px solid #fff',
            color: 'white',
            borderRadius: '2px',
            background: 'initial',
        },
        hover: {
            background: '#fff',
            color: '#ff5f6d',
        },
    },
    text: {
        main: {
            color: 'white',
            background: 'rgb(255 130 111)',
            // boxShadow:
            //   'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
            borderRadius: '2px',
        },
    },
};
exports.DEEP_SPACE_STYLE = {
    background: {
        type: styles_1.BackgroundType.Style,
        style: 'linear-gradient(to top, #000000, #434343)',
    },
    link: {
        main: {
            border: '2px solid #fff',
            color: 'white',
            borderRadius: '2px',
            background: 'initial',
        },
        hover: {
            background: '#fff',
            color: '#000000',
        },
    },
    text: {
        main: {
            color: 'white',
            background: '#111111',
            // boxShadow:
            //   'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
            borderRadius: '2px',
        },
    },
};
exports.DARK_SKIES_STYLE = {
    background: {
        type: styles_1.BackgroundType.Style,
        style: 'linear-gradient(to bottom, #4b79a1, #283e51)',
    },
    link: {
        main: {
            border: '2px solid #fff',
            color: 'white',
            borderRadius: '2px',
            background: 'initial',
        },
        hover: {
            background: '#fff',
            color: '#4b79a1',
        },
    },
    text: {
        main: {
            color: 'white',
            background: 'rgb(52 83 109)',
            // boxShadow:
            //   'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
            borderRadius: '2px',
        },
    },
};
exports.SUZY_STYLE = {
    background: {
        type: styles_1.BackgroundType.Style,
        style: 'linear-gradient(to top, #834d9b, #d04ed6)',
    },
    link: {
        main: {
            border: '2px solid #fff',
            color: 'white',
            borderRadius: '2px',
            background: 'initial',
        },
        hover: {
            background: '#fff',
            color: '#d04ed6',
        },
    },
    text: {
        main: {
            color: 'white',
            background: 'rgb(167 77 183)',
            // boxShadow:
            //   'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
            borderRadius: '2px',
        },
    },
};
exports.LITTLE_LEAF_STYLE = {
    background: {
        type: styles_1.BackgroundType.Style,
        style: 'linear-gradient(to top, #76b852, #8dc26f)',
    },
    link: {
        main: {
            border: '2px solid #fff',
            color: 'white',
            borderRadius: '2px',
            background: 'initial',
        },
        hover: {
            background: '#fff',
            color: '#76b852',
        },
    },
    text: {
        main: {
            color: 'white',
            background: '#76b852',
            // boxShadow:
            //   'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
            borderRadius: '2px',
        },
    },
};
exports.ANNIE_STYLES = {
    background: {
        type: styles_1.BackgroundType.Style,
        style: 'linear-gradient(to top, #ffafbd, #ffc3a0)',
    },
    link: {
        main: {
            border: '2px solid #fff',
            color: 'black',
            borderRadius: '5px 30px 30px 5px',
            background: 'white',
        },
        hover: {
            color: 'black',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
        },
    },
    text: {
        main: {
            color: 'white',
            background: 'rgb(236 169 142)',
            //   'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
            borderRadius: '2px',
        },
    },
};
