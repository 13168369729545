import { Component, FunctionComponent, ReactNode } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useEditor } from '../store';
import { isEmpty } from 'ramda';
import { LoadingScreen } from './LoadingScreen';

export interface PrivateRouteProps {
  component: Component;
  children: ReactNode;
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ component, children, ...rest }) => {
  const user = useEditor((editor) => editor.user);
  const loading = useEditor((editor) => editor.loading);
  const authChecked = useEditor((editor) => editor.authChecked);

  const history = useHistory();

  if (!authChecked || loading) {
    return <LoadingScreen />;
  }

  return isEmpty(user) ? (
    <Redirect
      to={{
        pathname: `/login`,
        search: `?to=${encodeURI(history.location.pathname)}`,
        state: { from: history.location },
      }}
    />
  ) : (
    <Route {...rest}>{children}</Route>
  );
};

export default PrivateRoute;
