import React, { FunctionComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { updateBlock, useBlockFromIndex } from '../../../store';
import { VideoBlockDefinition } from '@brandlink/models';
import { withHttp } from '../../../utils/helpers';
import ReactPlayer from 'react-player';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles<Theme>((theme) => ({
  playerWrappersWrapper: {
    maxWidth: '250px',
    margin: '8px auto 0',
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

export interface VideoBlockEditorProps {
  blockIndex: number;
}

const VideoBlockEditor: FunctionComponent<VideoBlockEditorProps> = ({ blockIndex }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const block = useBlockFromIndex(blockIndex) as VideoBlockDefinition;

  return (
    <>
      <TextField
        label="Url"
        fullWidth
        value={block.url}
        onChange={(e) =>
          dispatch(
            updateBlock({
              blockIndex,
              block: {
                ...block,
                url: e.target.value,
              } as VideoBlockDefinition,
            })
          )
        }
        onBlur={(e) => {
          dispatch(
            updateBlock({
              blockIndex,
              block: {
                ...block,
                url: withHttp(e.target.value),
              } as VideoBlockDefinition,
            })
          );
        }}
        variant="outlined"
        size="small"
      />
      {block.url && (
        <div className={classes.playerWrappersWrapper}>
          <div className={classes.playerWrapper}>
            <ReactPlayer
              className={classes.reactPlayer}
              url={block.url}
              width="100%"
              height="100%"
              controls
              light={true}
              onClickCapture={(event) => {
                // STOP AUTOPLAY
                event.stopPropagation();
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export { VideoBlockEditor };
