import React, { FunctionComponent } from 'react';
import { SocialsPickerModal } from '../Blocks/SocialsBlock/SocialsPickerModal';
import { ImagePickerModal } from './Appearance/ImagePickerModal';
import { ContextMenu } from './ContextMenu/ContextMenu';
import { Fabs } from './Fabs/Fabs';
import { MobileVisualEditorFabs } from './Fabs/MobileVisualEditorFabs';
import { AddBlockModal } from './Modals/AddBlockModal';
import { SignupModal } from './Modals/SignupModal';

export interface AdminDependenciesProps {
  mobile?: boolean;
}

export const AdminDependencies: FunctionComponent<AdminDependenciesProps> = ({ mobile }) => {
  return (
    <>
      {mobile ? <MobileVisualEditorFabs /> : <Fabs />}
      <ContextMenu />
      {/* Modals */}
      <AddBlockModal />
      <ImagePickerModal />
      <SignupModal />
      <SocialsPickerModal />
    </>
  );
};
