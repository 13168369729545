import Fab from '@material-ui/core/Fab';
import { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  resetChanges,
  setIsSignupModalOpen,
  setPreview,
  uploadChanges,
  useEditor,
  useEditorChanged,
  useShouldOpenSignupModal,
} from '../../../store';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { Fade } from '@material-ui/core';
import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import { Link, useRouteMatch } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { green, red } from '@material-ui/core/colors';
const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: '8px',
    right: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
  fabs: {
    display: 'flex',
    flexDirection: 'column',
  },
  back: {
    position: 'fixed',
    top: '8px',
    left: '8px',
    backgroundColor: '#00000050',
    color: '#ffffffd0',
    '&:hover': {
      backgroundColor: '#00000070',
    },
  },
}));

export const MobileVisualEditorFabs: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const preview = useEditor((editor) => editor.preview);
  const changed = useEditorChanged();
  const shouldOpenSignupModal = useShouldOpenSignupModal();
  const uploading = useEditor((editor) => editor.uploading);
  const match = useRouteMatch();

  return (
    <>
      <IconButton className={classes.back} component={Link} to={`${match.path}/`}>
        <ArrowBackIosIcon />
      </IconButton>
      <div className={classes.fab}>
        {changed && (
          <Fade in={true}>
            <div className={classes.fabs}>
              <Fab
                size="medium"
                color="primary"
                aria-label="add"
                sx={{
                  mt: 1,
                  backgroundColor: green[500],
                  '&:hover': {
                    backgroundColor: green[700],
                  },
                }}
                disabled={uploading}
                onClick={() => {
                  if (shouldOpenSignupModal) {
                    return dispatch(setIsSignupModalOpen(true));
                  }
                  dispatch(uploadChanges())
                    .then(unwrapResult)
                    .then((success) => {
                      toast.success('Successfully saved', { autoClose: 2000 });
                    })
                    .catch((err) => {
                      toast.error('Error saving');
                    });
                }}
              >
                <SaveIcon />
              </Fab>
              <Fab
                size="medium"
                color="secondary"
                aria-label="add"
                sx={{
                  mt: 1,
                  backgroundColor: red[500],
                  '&:hover': {
                    backgroundColor: red[700],
                  },
                }}
                disabled={uploading}
                onClick={() => dispatch(resetChanges({}))}
              >
                <CloseIcon />
              </Fab>
            </div>
          </Fade>
        )}
        <Fab
          size="medium"
          color="primary"
          aria-label="add"
          sx={{ mt: 1 }}
          onClick={() => dispatch(setPreview(!preview))}
        >
          {preview ? <EditIcon /> : <VisibilityIcon />}
        </Fab>
      </div>
    </>
  );
};
