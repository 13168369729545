import React, { FunctionComponent } from 'react';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface FeedbackFormValues {
  feedback: string;
  favouriteFeature?: string;
  email: string;
}

export const Contribute: FunctionComponent = () => {
  const classes = useStyles();

  const initialValues: FeedbackFormValues = {
    feedback: '',
    email: '',
    favouriteFeature: '',
  };
  const handleSubmit = async (values: FeedbackFormValues) => {
    try {
      console.log('submit');
      const response = await axios.post('/api/feedback', values);
      if (response.status === 200) {
        toast.success('Thanks for submitting. We will be in touch with you shortly! :)', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      toast.error('Could not submit, please try again! :)', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Container className={classes.root} maxWidth="lg" sx={{ py: '50px ' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2">Request a new feature or submit feedback</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">
            Get the chance to have your name engraved on our website!
          </Typography>
          <Typography variant="body1">
            All feedback is welcome. Feel free to submit a bug report, express your concerns,
            request a new feature or just email me at stuffbyliang@gmail.com
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ handleChange, isSubmitting }) => (
              <Form>
                <Typography variant="subtitle2">
                  Have an idea or have any feedback/bugs for us?
                </Typography>
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  rows={4}
                  label="Request"
                  name="feedback"
                />
                <Typography variant="subtitle2">What do you like most about BrandLink?</Typography>
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  multiline
                  rows={4}
                  label="Feedback"
                  name="favouriteFeature"
                />
                <Typography variant="subtitle2">What's your email?</Typography>
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  label="Email"
                  autoComplete="email"
                />
                <Grid container>
                  <Grid item xs></Grid>
                  <Grid item>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      loading={isSubmitting}
                      size="large"
                      sx={{ mt: 2 }}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
};
