import { FunctionComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { updateBlock, useBlockFromIndex } from '../../../store';
import { TextBlockDefinition } from '@brandlink/models';

export interface TextBlockEditorProps {
  blockIndex: number;
}

const TextBlockEditor: FunctionComponent<TextBlockEditorProps> = ({ blockIndex }) => {
  const dispatch = useAppDispatch();

  const block = useBlockFromIndex(blockIndex) as TextBlockDefinition;

  return (
    <>
      <TextField
        label="Text"
        fullWidth
        value={block.text}
        onChange={(e) =>
          dispatch(
            updateBlock({
              blockIndex,
              block: {
                ...block,
                text: e.target.value,
              } as TextBlockDefinition,
            })
          )
        }
        multiline
        variant="outlined"
        size="small"
        sx={{ marginBottom: 1 }}
      />
    </>
  );
};

export { TextBlockEditor };
