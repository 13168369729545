import { createTheme, responsiveFontSizes, Theme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles' {
  interface Theme {
    card: any;
  }

  interface ThemeOptions {
    card: any;
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
    subtitle2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    subtitle2?: React.CSSProperties;
  }
}

declare module '@material-ui/styles' {
  interface DefaultTheme extends Theme {
    card: any;
  }

  interface ThemeOptions {
    card?: any;
  }
}

declare module '@material-ui/core/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    subtitle2: true;
  }
}

declare module '@material-ui/core/Avatar' {
  interface AvatarPropsVariantOverrides {
    green: true;
    yellow: true;
    red: true;
    blue: true;
  }
}

export const theme = responsiveFontSizes(
  createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            boxShadow: 'none !important',
            '& .MuiButton-label': {
              fontWeight: 600,
            },
          },
        },
      },
      MuiFab: {
        defaultProps: {
          disableRipple: false,
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // boxShadow: 'none',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            // color: '#536DFE',
          },
        },
      },
      MuiAvatar: {
        variants: [
          {
            props: { variant: 'green' },
            style: {
              borderRadius: '4px',
              background: 'rgb(224, 242, 241)',
              color: '#009688',
            },
          },
          {
            props: { variant: 'red' },
            style: {
              borderRadius: '4px',
              backgroundColor: 'rgb(252, 228, 236)',
              color: ' rgb(233, 30, 99)',
            },
          },
          {
            props: { variant: 'yellow' },
            style: {
              borderRadius: '4px',
              backgroundColor: 'rgb(255, 248, 225)',
              color: 'rgb(255, 193, 7)',
            },
          },
          {
            props: { variant: 'blue' },
            style: {
              borderRadius: '4px',
              backgroundColor: 'rgb(227, 242, 253)',
              color: 'rgb(33, 150, 243)',
            },
          },
        ],
      },
    },
    palette: {
      primary: {
        main: '#536DFE',
      },
      secondary: {
        main: '#101935',
      },
    },
    card: {
      padding: 15,
      width: '100%',
      maxWidth: 650,
      marginBottom: 15,
      boxSizing: 'border-box',
    },
    typography: {
      fontFamily: ['Inter', 'Roboto', 'Arial', 'sans-serif'].join(','),
      button: {
        textTransform: 'none',
      },
      h2: {
        lineHeight: 1.2,
        fontWeight: 900,
        margin: '0px 0px 0.35em',
        color: 'rgb(45, 55, 72)',
      },
      h3: {
        fontWeight: 700,
        color: 'rgb(45, 55, 72)',
        margin: '0px 0px 0.35em',
      },
      h4: {
        color: 'rgb(45, 55, 72)',
        fontSize: '2rem',
        lineHeight: 1.235,
        fontWeight: 700,
        margin: '0px 0px 0.25em',
      },
      h5: {
        fontSize: '1.15rem',
        fontWeight: 500,
      },
      h6: {
        color: 'rgb(100, 110, 115)',
        lineHeight: 1.6,
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: '1rem',
        fontWeight: 500,
      },
      body1: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.5,
        color: 'rgb(100, 110, 115)',
      },
      body3: {
        color: 'inherit',
      },
    },
  })
);
