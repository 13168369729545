import { InputBase, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import { BLUE_STYLE, TextBlockDefinition, Styles } from '@brandlink/models';
import { updateBlock, useEditor } from '../../../store/editorSlice';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { isEmpty } from 'ramda';

const useStyles = makeStyles<Theme, Styles>((theme) => ({
  container: ({ text }) => ({
    ...text?.main,
    whiteSpace: 'pre-wrap',
    boxSizing: 'border-box',
    minHeight: '100%',
    textAlign: 'left',
    transition: '0.3s',
    position: 'relative',
    fontSize: '1rem',
    padding: '17px 10px',
    '&:hover': { ...text?.hover },
  }),
  dragging: ({ text }) => ({ ...text?.hover }),
  inputBase: {
    color: 'inherit',
    padding: 0,
    '& .MuiInputBase-input': {
      padding: 0,
      lineHeight: 'normal',
      height: 'inherit',
      textAlign: 'left',
    },
  },
  editing: {},
  wrap: {
    overflowWrap: 'anywhere',
  },
}));

export interface TextBlockEditorProps {
  block: TextBlockDefinition;
  isDragging: boolean;
  blockIndex: number;
  editing: boolean;
}

const TextBlockVisualEditor: FunctionComponent<TextBlockEditorProps> = React.memo(
  ({ block, isDragging, blockIndex, editing }) => {
    const dispatch = useAppDispatch();
    const styles = useEditor((editor) => editor.styles);
    const classes = useStyles(isEmpty(styles ?? {}) ? BLUE_STYLE : styles!);

    const { text } = block;

    return (
      <div
        className={clsx(
          classes.container,
          isDragging && classes.dragging,
          editing && classes.editing
        )}
      >
        {editing ? (
          <>
            <InputBase
              className={classes.inputBase}
              placeholder="Text"
              value={text}
              onChange={(e) =>
                dispatch(
                  updateBlock({
                    blockIndex,
                    block: {
                      ...block,
                      text: e.target.value,
                    } as TextBlockDefinition,
                  })
                )
              }
              multiline
              fullWidth
              autoFocus
            />
          </>
        ) : (
          <>
            <Typography className={classes.wrap} variant="body3" component="p">
              {text || 'Double click to edit'}
            </Typography>
          </>
        )}
      </div>
    );
  }
);

export { TextBlockVisualEditor };
