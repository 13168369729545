import { FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useAppDispatch } from '../../hooks/useAppStore';
import { setPhoneScale, setPreview, useEditor } from '../../store/editorSlice';
import { DndEditor } from '../DndEditor/DndEditor';
import { PHONE_WIDTH, PHONE_HEIGHT, Phone } from '../Phone/Phone';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    padding: '20px',
    boxSizing: 'border-box',
    background: theme.palette.grey[100],
    display: 'flex',
    flexDirection: 'column',
  },
  switchContainer: {
    textAlign: 'center',
  },
  phoneContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexGrow: 1,
  },
  inner: {
    position: 'absolute',
    height: 'auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
}));

const PhoneEditor: FunctionComponent = () => {
  const classes = useStyles();
  const { width: oldWidth, height: oldHeight, ref } = useResizeDetector();
  const dispatch = useAppDispatch();
  const preview = useEditor((editor) => editor.preview);

  let height: number | undefined;

  if (oldWidth && oldHeight) {
    height = (oldWidth * PHONE_HEIGHT) / PHONE_WIDTH; // calculate height based on aspect ratio

    if (height > oldHeight) {
      height = oldHeight;
    }
  }

  let scale = height ? height / PHONE_HEIGHT : 1;

  useEffect(() => {
    dispatch(setPhoneScale(scale));
  }, [dispatch, scale]);

  return (
    <div className={classes.container}>
      <div className={classes.switchContainer}>
        <FormControlLabel
          control={
            <Switch
              checked={preview}
              onChange={(event) => dispatch(setPreview(event.target.checked))}
              color="primary"
            />
          }
          label="Preview"
        />
      </div>

      <div className={classes.phoneContainer} ref={ref}>
        <div className={classes.inner}>
          <Phone scale={scale}>
            <DndEditor scale={scale} />
          </Phone>
        </div>
      </div>
    </div>
  );
};

export { PhoneEditor };
