import { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { AdminTabs } from './Tabs/AdminTabs';
import { AdminTabsControl } from './Tabs/AdminTabsControl';
import { Hidden } from '../ui/Hidden';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Admin: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Hidden down="md">
        <AdminTabsControl />
      </Hidden>
      <AdminTabs />
    </div>
  );
};

export default Admin;
