import { FunctionComponent } from 'react';
import { Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { setIsAddBlockModalOpen, setSelectedBlockIndex } from '../../../store/editorSlice';
import { deleteBlock } from '../../../store/';
import { useAppDispatch, useAppStore } from '../../../hooks/useAppStore';
import { UsernameUpdate } from './UsernameUpdate';
import { blockConfig } from '../../../config/blockConfig';
import { BlockEditor } from '../../Blocks/BlockEditor';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { equals } from 'ramda';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.grey[100],
  },
  card: theme.card,
  item: {
    maxWidth: 650,
    width: '100%',
    marginBottom: 15,
  },
}));

const Editor: FunctionComponent = () => {
  const classes = useStyles();
  const blocks = useAppStore(
    (state) =>
      state.editor.blocks.map(({ type, _id, draggableId }) => ({
        type,
        _id,
        draggableId,
      })),
    equals
  );
  const dispatch = useAppDispatch();

  const handleDelete = async (blockIndex: number) => {
    await dispatch(setSelectedBlockIndex(undefined));
    await dispatch(deleteBlock(blockIndex));
  };

  return (
    <Box
      className={classes.container}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        boxSizing: 'border-box',
        p: 3,
      }}
    >
      <div className={classes.item}>
        <Button
          fullWidth
          onClick={() => dispatch(setIsAddBlockModalOpen({ open: true }))}
          endIcon={<AddIcon />}
          variant="contained"
        >
          Add new block
        </Button>
      </div>
      <Paper className={classes.card}>
        <UsernameUpdate />
      </Paper>
      {blocks.map((block, blockIndex) => {
        const Component = blockConfig[block.type].editorComponent;
        if (!Component) {
          console.warn('Invalid block', block);
          return null;
        }

        return (
          <BlockEditor
            key={blockIndex}
            blockIndex={blockIndex}
            title={`${blockConfig[block.type].name} Block`}
            handleDelete={() => handleDelete(blockIndex)}
          >
            <Component blockIndex={blockIndex} />
          </BlockEditor>
        );
      })}
    </Box>
  );
};

export { Editor };
