import { FunctionComponent } from 'react';
import { Container, Typography } from '@material-ui/core';

export const NotFound: FunctionComponent = () => {
  return (
    <Container maxWidth="lg">
      <Typography variant="h1">404 not found</Typography>
    </Container>
  );
};
