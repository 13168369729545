import { FunctionComponent } from 'react';

import { TextBlockDefinition, Styles } from '@brandlink/models';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { BLUE_STYLE } from '@brandlink/models';
import { isEmpty } from 'ramda';

export interface TextBlockProps {
  block: TextBlockDefinition;
  styles?: Styles;
}

const useStyles = makeStyles<Theme, Styles>((theme) => ({
  text: ({ text }) => ({
    ...text?.main,
    whiteSpace: 'pre-wrap',
    transition: '0.3s',
    fontSize: '1rem',
    padding: '17px 8px',
    '&:hover': {
      ...text?.hover,
    },
  }),
}));

const TextBlock: FunctionComponent<TextBlockProps> = ({ block, styles }) => {
  const classes = useStyles(isEmpty(styles ?? {}) ? BLUE_STYLE : styles!);

  if (!block.text) return null;

  return <div className={classes.text}>{block.text}</div>;
};

export { TextBlock };
