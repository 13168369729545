"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Socials = exports.BlockType = void 0;
var BlockType;
(function (BlockType) {
    BlockType[BlockType["Link"] = 0] = "Link";
    BlockType[BlockType["Video"] = 1] = "Video";
    BlockType[BlockType["Socials"] = 2] = "Socials";
    BlockType[BlockType["Text"] = 3] = "Text";
})(BlockType = exports.BlockType || (exports.BlockType = {}));
var Socials;
(function (Socials) {
    Socials["Phone"] = "phone";
    Socials["Website"] = "website";
    Socials["Email"] = "email";
    Socials["Facebook"] = "facebook";
    Socials["Youtube"] = "youtube";
    Socials["Instagram"] = "instagram";
    Socials["Snapchat"] = "snapchat";
    Socials["Twitch"] = "twitch";
    Socials["Tiktok"] = "tiktok";
    Socials["Twitter"] = "twitter";
    Socials["Linkedin"] = "linkedin";
    Socials["Patreon"] = "patreon";
    Socials["Whatsapp"] = "whatsapp";
    Socials["Behance"] = "behance";
    Socials["Dribble"] = "dribble";
    Socials["Pinterest"] = "pinterest";
    Socials["Github"] = "github";
})(Socials = exports.Socials || (exports.Socials = {}));
