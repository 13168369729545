import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppStore } from '../hooks/useAppStore';
import { shallowEqual, TypedUseSelectorHook } from 'react-redux';

export interface DndSliceState {
  placeholderHeight: number;
  placeholderWidth: number;
  destinationDroppableId: string | undefined;
}

export const dndSlice = createSlice({
  name: 'dnd',
  initialState: {
    placeholderHeight: 0,
    placeholderWidth: 0,
  } as DndSliceState,
  reducers: {
    setPlaceHolderDimensions: (
      state,
      action: PayloadAction<{
        width: number;
        height: number;
      }>
    ) => {
      return {
        ...state,
        placeholderWidth: action.payload.width,
        placeholderHeight: action.payload.height,
      };
    },
    setDestinationDroppableId: (state, action: PayloadAction<string | undefined>) => {
      state.destinationDroppableId = action.payload;
    },
  },
});

export const { setPlaceHolderDimensions, setDestinationDroppableId } = dndSlice.actions;

export const dndReducer = dndSlice.reducer;
export const useDnd: TypedUseSelectorHook<DndSliceState> = (fn, equalityfn) =>
  useAppStore(({ dnd }) => fn(dnd), equalityfn ?? shallowEqual);
