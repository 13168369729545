import { FunctionComponent } from 'react';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ImagePickerModal } from './ImagePickerModal';
import { StylePicker } from './StylePicker/StylePicker';
import { AvatarCardEditor } from '../AvatarCard/AvatarCardEditor';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.grey[100],
  },
  card: theme.card,
}));

const Appearance: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        boxSizing: 'border-box',
        p: 3,
      }}
    >
      <Paper className={classes.card}>
        <AvatarCardEditor />
        <ImagePickerModal />
      </Paper>
      <Paper className={classes.card}>
        <StylePicker />
      </Paper>
    </Box>
  );
};

export { Appearance };
