import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles((theme) => ({
  card: theme.card,
}));

export interface BlockEditorProps {
  title: string;
  handleDelete: () => void;
  blockIndex: number;
}

const BlockEditor: FunctionComponent<BlockEditorProps> = ({
  title,
  children,
  handleDelete,
  blockIndex,
}) => {
  const classes = useStyles();

  return (
    <Fade in={true}>
      <Paper className={classes.card} id={`block-editor-${blockIndex}`}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
          <Typography display="inline" variant="h5">
            {title}
          </Typography>
          <IconButton aria-label="delete" onClick={handleDelete} size="small">
            <DeleteIcon />
          </IconButton>
        </Box>
        {children}
      </Paper>
    </Fade>
  );
};
export { BlockEditor };
