import Fab from '@material-ui/core/Fab';
import React, { FunctionComponent } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import {
  resetChanges,
  setIsSignupModalOpen,
  uploadChanges,
  useEditor,
  useEditorChanged,
  useShouldOpenSignupModal,
} from '../../../store';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    bottom: '16px',
    right: '16px',
  },
}));

export const DesktopChangeFabs: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const uploading = useEditor((editor) => editor.uploading);
  const shouldOpenSignupModal = useShouldOpenSignupModal();
  const changed = useEditorChanged();

  if (!changed) {
    return null;
  }

  return (
    <Fade in={true}>
      <span className={classes.root}>
        <Fab
          onClick={() => {
            if (shouldOpenSignupModal) {
              return dispatch(setIsSignupModalOpen(true));
            }
            dispatch(uploadChanges())
              .then(unwrapResult)
              .then((success) => {
                toast.success('Successfully saved', { autoClose: 2000 });
              })
              .catch((err) => {
                toast.error('Error saving');
              });
          }}
          disabled={uploading}
          variant="extended"
          color="primary"
          sx={{ mr: 1 }}
        >
          <SaveIcon sx={{ mr: 1 }} />
          Save Changes
        </Fab>
        <Fab
          disabled={uploading}
          onClick={() => dispatch(resetChanges({}))}
          variant="extended"
          color="secondary"
        >
          <ClearIcon sx={{ mr: 1 }} />
          Reset Changes
        </Fab>
      </span>
    </Fade>
  );
};
