import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import { useAppDispatch } from '../../../../hooks/useAppStore';
import { setStyles } from '../../../../store';
import { Styles } from '@brandlink/models';

const useStyles = makeStyles<Theme, Styles>((theme) => ({
  button: ({ link }) => ({
    ...link?.main,
    transition: '0.3s',
    fontSize: '0.8rem',
    padding: '8px 0',
    '&:hover': {
      ...link?.hover,
    },
  }),
  container: ({ background }) => ({
    background: (background as any)?.style,
    padding: '20px',
    textAlign: 'center',
  }),
  root: {
    // border: '1px solid',
    // borderRadius: '3px',
    cursor: 'pointer',
    transition: '0.3s',
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
  },
}));

export interface StyleProps {
  name: string;
  style: Styles;
}

const Style: FunctionComponent<StyleProps> = ({ name, style }) => {
  const classes = useStyles(style);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setStyles(style));
  };

  return (
    <Paper onClick={handleClick} className={classes.root} elevation={3}>
      <Typography textAlign="center" variant="subtitle1">
        {name}
      </Typography>
      <div className={classes.container}>
        <div className={classes.button}>Link Here</div>
      </div>
    </Paper>
  );
};

export { Style };
