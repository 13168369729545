import { FunctionComponent } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as GoogleIcon } from '../../images/google-icon.svg';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  submit: {},
}));

export const GoogleLogin: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Button
      fullWidth
      variant="outlined"
      href="/api/auth/google"
      size="large"
      className={classes.submit}
      startIcon={
        <SvgIcon fontSize="inherit">
          <GoogleIcon />
        </SvgIcon>
      }
    >
      Sign in with Google
    </Button>
  );
};
