import React, { FunctionComponent } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '& .MuiTab-root': {
      fontWeight: '500',
      color: '#333',
      minWidth: 'inherit',
      padding: '6px 25px',
      fontSize: '1rem',
    },
    '& .Mui-selected': {
      color: theme.palette.primary.main,
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      height: '2px',
    },
    '& .MuiTabs-indicatorSpan': {
      width: '70%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '15px 15px 0 0',
    },
  },
}));

export const AdminTabsControl: FunctionComponent = () => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  let match = useRouteMatch();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Tabs
      className={classes.tabs}
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    >
      <Tab label="Editor" component={Link} to={`${match.path}/`} />
      <Tab label="Appearance" component={Link} to={`${match.path}/appearance`} />
      <Tab label="Analytics" component={Link} to={`${match.path}/analytics`} />
      <Tab label="Feedback" component={Link} to={`${match.path}/feedback`} />
    </Tabs>
  );
};
