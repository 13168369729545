import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import { FunctionComponent } from 'react';
import { editUserFields, setIsImageUploadModalOpen, useEditor } from '../../../store';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { makeStyles } from '@material-ui/styles';
import { useAppDispatch } from '../../../hooks/useAppStore';
import TextField from '@material-ui/core/TextField';
import defaultProfileImage from '../../../images/profile.png';
import { equals } from 'ramda';

const useStyles = makeStyles((theme) => ({
  avatar: { width: 96, height: 96 },
  badge: {
    cursor: 'pointer',
    marginTop: '20px',
  },
  icon: {
    opacity: '0.5',
    fontSize: '30px',
    marginRight: '15px',
    marginBottom: '15px',
  },
}));

export const AvatarCardEditor: FunctionComponent = () => {
  const classes = useStyles();
  const user = useEditor((editor) => editor.user);
  const editedUserFields = useEditor((editor) => editor.editedUserFields, equals);
  const dispatch = useAppDispatch();

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className={classes.badge}
        badgeContent={<AddCircleRoundedIcon className={classes.icon} />}
        onClick={() => dispatch(setIsImageUploadModalOpen(true))}
      >
        <Avatar
          className={classes.avatar}
          src={editedUserFields.avatar ?? (user.avatar || defaultProfileImage)}
        />
      </Badge>
      <Box sx={{ pl: 2, flexGrow: 1 }}>
        <TextField
          value={editedUserFields.title ?? (user.title || '')}
          onChange={(e) => dispatch(editUserFields({ title: e.target.value }))}
          variant="outlined"
          margin="dense"
          size="small"
          fullWidth
          label="Title"
        />
        <TextField
          value={editedUserFields.bio ?? (user.bio || '')}
          onChange={(e) => dispatch(editUserFields({ bio: e.target.value }))}
          variant="outlined"
          margin="dense"
          size="small"
          fullWidth
          label="Your bio"
          multiline
          minRows={3}
        />
      </Box>
    </Box>
  );
};
