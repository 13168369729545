import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { FunctionComponent, useState } from 'react';
import { useAppDispatch } from '../../../hooks/useAppStore';
import {
  addSocialsToSocialsBlock,
  setIsSocialsPickerModalOpen,
  useBlockFromIndex,
  useEditor,
} from '../../../store';
import { makeStyles } from '@material-ui/styles';
import { SOCIALS } from '../../../config/socialsConfig';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { Socials, SocialsBlockDefinition } from '@brandlink/models';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0 24px 24px 24px',
  },
  container: {
    transition: '0.3s',
    textAlign: 'center',
    cursor: 'pointer',
    padding: '8px 0',
    background: theme.palette.grey[300],
    borderRadius: '10px',
    '&:hover': {
      background: theme.palette.grey[400],
    },
  },
  icon: {
    fontSize: '26px',
  },
  iconSearchContainer: {
    overflowY: 'scroll',
    height: '550px',
    alignContent: 'flex-start',
  },
}));

export const SocialsPickerModal: FunctionComponent = () => {
  const classes = useStyles();
  const isSocialsPickerModalOpen = useEditor((editor) => editor.isSocialsPickerModalOpen);
  const selectedBlockIndex = useEditor((editor) => editor.selectedBlockIndex);
  const block = useBlockFromIndex(selectedBlockIndex!) as SocialsBlockDefinition;
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState('');

  const handleClose = async () => {
    await dispatch(setIsSocialsPickerModalOpen(false));
  };

  const handleClick = async (social: Socials) => {
    await dispatch(
      addSocialsToSocialsBlock({
        blockIndex: selectedBlockIndex!,
        social,
      })
    );
    await handleClose();
  };

  return (
    <Dialog open={isSocialsPickerModalOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Socials Link</DialogTitle>
      <Grid className={classes.wrapper} container spacing={2}>
        <Grid item xs={12}>
          <TextField
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            fullWidth
            label="Search for socials"
          />
        </Grid>
      </Grid>
      <Grid className={clsx(classes.wrapper, classes.iconSearchContainer)} container spacing={2}>
        {Object.entries(SOCIALS)
          .filter(([key]) => !block?.socials?.find(({ name }) => name === key)) // filter out those that have been used already
          .filter(([_, { prettyName }]) => prettyName.toLowerCase().includes(filter.toLowerCase()))
          .map(([social, { icon, prettyName }]) => {
            return (
              <Grid key={social} item xs={6} sm={4}>
                <div onClick={() => handleClick(social as Socials)} className={classes.container}>
                  <div className={classes.icon}>{icon}</div>
                  <div>{prettyName}</div>
                </div>
              </Grid>
            );
          })}
      </Grid>
    </Dialog>
  );
};
