import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { FunctionComponent } from 'react';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { addBlock, setIsAddBlockModalOpen, useEditor } from '../../../store';
import { makeStyles } from '@material-ui/styles';
import { blockConfig } from '../../../config/blockConfig';
import { BlockDefinition } from '@brandlink/models';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0 24px 24px 24px',
  },
  container: {
    transition: '0.3s',
    textAlign: 'center',
    cursor: 'pointer',
    padding: '8px 0',
    background: theme.palette.grey[300],
    borderRadius: '10px',
    '&:hover': {
      background: theme.palette.grey[400],
    },
  },
}));

const AddBlockModal: FunctionComponent = () => {
  const classes = useStyles();
  const { isAddBlockModalOpen, newBlockIndex } = useEditor(
    ({ isAddBlockModalOpen, newBlockIndex }) => ({
      isAddBlockModalOpen,
      newBlockIndex,
    })
  );
  const dispatch = useAppDispatch();

  const handleClose = async () => {
    await dispatch(setIsAddBlockModalOpen({ open: false }));
  };

  const handleClick = async (block: BlockDefinition) => {
    await dispatch(
      addBlock({
        blockIndex: newBlockIndex!,
        block,
      })
    );
    await handleClose();

    if (document.querySelector('#admin-main-container')) {
      document
        .querySelector('#admin-main-container')!
        .scrollTo(0, document.querySelector('#admin-main-container')!.scrollHeight);
    }
  };

  return (
    <Dialog open={isAddBlockModalOpen} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>Add Block</DialogTitle>
      <Grid className={classes.wrapper} container spacing={2}>
        {Object.keys(blockConfig).map((key) => {
          const block = blockConfig[key];
          return (
            <Grid key={key} item xs={6}>
              <div onClick={() => handleClick(block.defaultBlock)} className={classes.container}>
                <block.icon />
                <div>{block.name}</div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Dialog>
  );
};

export { AddBlockModal };
