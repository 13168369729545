import { BlockDefinition, Socials, SocialsBlockDefinition, Styles, User } from '@brandlink/models';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { equals, isEmpty, reject } from 'ramda';
import { shallowEqual, TypedUseSelectorHook } from 'react-redux';
import {
  addSocials,
  deleteSocials,
  getUpdatedSocialsBlock,
  reorderSocialsBlock,
} from '../components/Blocks/SocialsBlock/socialsBlockUtils';
import { useAppStore } from '../hooks/useAppStore';
import { dataURLtoFile, forceImageReload, getRandomId, reorder } from '../utils/helpers';
import { clearState, SavedState, saveState } from '../utils/localstorageUtils';
import { AppDispatch, RootState } from './store';

export interface EditorSliceState {
  user: User;
  editedUserFields: Partial<User>;
  loading: boolean;
  authChecked: boolean; // has the PrivateRoute requested the user already

  preview: boolean;
  initialBlocks: BlockDefinition[];
  blocks: BlockDefinition[];
  initialStyles?: Styles;
  styles?: Styles;
  selectedBlockIndex?: number;
  initialized: boolean;
  uploading: boolean;

  isAddBlockModalOpen: boolean;
  isSignupModalOpen: boolean;
  isImageUploadModalOpen: boolean;
  isSocialsPickerModalOpen: boolean;

  newBlockIndex?: number;
  mousePos: {
    x: number | null;
    y: number | null;
  };
  phoneScale: number;
}

export const getUser = createAsyncThunk('user/getUser', async () => {
  const { data } = await axios.get<User>('/api/users/current');
  return data;
});

export const logout = createAsyncThunk('user/logout', async () => {
  await axios.get('/api/auth/logout');
});

export const uploadChanges = createAsyncThunk<
  User,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('editor/uploadChanges', async (unused, { getState }) => {
  const { blocks, initialBlocks, styles, initialStyles, editedUserFields } = getState().editor;

  let changes: Partial<User> = { ...editedUserFields };

  if (!equals(blocks, initialBlocks)) {
    changes.blocks = blocks;
  }
  if (!equals(styles, initialStyles)) {
    changes.styles = styles;
  }

  // handle avatar upload first and seperately
  if (changes.avatar) {
    const formData = new FormData();
    formData.append('avatar', dataURLtoFile(changes.avatar, 'avatar'));
    const { data } = await axios.post('/api/users/current/avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    delete changes.avatar;
    if (isEmpty(changes)) return data;
  }

  if (!isEmpty(changes)) {
    const { data } = await axios.put<any>('/api/users/current', changes);
    console.log(changes, data);

    return data;
  }
});

export const editorSlice = createSlice({
  name: 'editor',
  initialState: {
    user: {} as User,
    editedUserFields: {},
    loading: false,
    authChecked: false,

    preview: false,
    initialBlocks: [],
    blocks: [],
    initialStyles: undefined,
    styles: undefined,
    selectedBlockIndex: undefined,
    initialized: false,
    uploading: false,

    isAddBlockModalOpen: false,
    isSignupModalOpen: false,
    isImageUploadModalOpen: false,
    isSocialsPickerModalOpen: false,
    newBlockIndex: undefined,
    mousePos: { x: null, y: null },
    phoneScale: 1,
  } as EditorSliceState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        } as User,
      };
    },
    editUserFields: (state, action: PayloadAction<Partial<User>>) => {
      const newEditedUserFields = reject((x) => !x, {
        ...state.editedUserFields,
        ...action.payload,
      });

      return {
        ...state,
        editedUserFields: newEditedUserFields,
      };
    },
    updateUsername: (state, action: PayloadAction<string>) => {
      if (state.user) state.user.username = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAuthChecked: (state, action: PayloadAction<boolean>) => {
      state.authChecked = action.payload;
    },
    setPreview: (state, action: PayloadAction<boolean>) => {
      state.preview = action.payload;
    },
    setSelectedBlockIndex: (state, action: PayloadAction<number | undefined>) => {
      state.selectedBlockIndex = action.payload;
    },
    initializeEditor: (
      state,
      action: PayloadAction<{ blocks: BlockDefinition[]; styles?: Styles }>
    ) => {
      const blocks = action.payload.blocks;

      return {
        ...state,
        ...action.payload,
        blocks,
        initialBlocks: blocks,
        initialStyles: action.payload.styles,
        initialized: true,
      };
    },
    setBlocks: (state, action: PayloadAction<BlockDefinition[]>) => {
      state.blocks = action.payload;
    },
    reorderBlocks: (state, action: PayloadAction<{ source: number; destination: number }>) => {
      state.blocks = reorder(
        state.blocks,
        action.payload.source,
        action.payload.destination
      ) as BlockDefinition[];
    },
    setStyles: (state, action: PayloadAction<Styles>) => {
      state.styles = action.payload;
    },
    updateBlock: (state, action: PayloadAction<{ blockIndex: number; block: BlockDefinition }>) => {
      state.blocks[action.payload.blockIndex] = action.payload.block;
    },
    updateSocialsBlock: (
      state,
      action: PayloadAction<{ blockIndex: number; social: Socials; value: string }>
    ) => {
      state.blocks[action.payload.blockIndex] = getUpdatedSocialsBlock(
        state.blocks[action.payload.blockIndex] as SocialsBlockDefinition,
        action.payload.social,
        action.payload.value
      );
    },
    reorderSocialsForSocialsBlock: (
      state,
      action: PayloadAction<{ blockIndex: number; source: number; destination: number }>
    ) => {
      state.blocks[action.payload.blockIndex] = reorderSocialsBlock(
        state.blocks[action.payload.blockIndex] as SocialsBlockDefinition,
        action.payload.source,
        action.payload.destination
      );
    },
    addSocialsToSocialsBlock: (
      state,
      action: PayloadAction<{ blockIndex: number; social: Socials }>
    ) => {
      state.blocks[action.payload.blockIndex] = addSocials(
        state.blocks[action.payload.blockIndex] as SocialsBlockDefinition,
        action.payload.social
      );
    },
    deleteSocialsFromSocialsBlock: (
      state,
      action: PayloadAction<{ blockIndex: number; social: Socials }>
    ) => {
      state.blocks[action.payload.blockIndex] = deleteSocials(
        state.blocks[action.payload.blockIndex] as SocialsBlockDefinition,
        action.payload.social
      );
    },
    deleteBlock: (state, action: PayloadAction<number>) => {
      const newBlocks = state.blocks.filter((block, i) => i !== action.payload);
      state.blocks = newBlocks;
    },
    addBlock: (
      state,
      action: PayloadAction<{ block: Partial<BlockDefinition>; blockIndex: number }>
    ) => {
      const { block, blockIndex } = action.payload;
      state.blocks.splice(blockIndex, 0, {
        type: 0,
        draggableId: getRandomId(),
        ...block,
      });
    },
    resetChanges: (state, action: PayloadAction<any>) => {
      clearState();
      return {
        ...state,
        blocks: state.initialBlocks,
        selectedBlockIndex: undefined,
        styles: state.initialStyles,
        editedUserFields: {},
      };
    },
    setIsAddBlockModalOpen: (state, action: PayloadAction<{ open: boolean; index?: number }>) => {
      return {
        ...state,
        isAddBlockModalOpen: action.payload.open,
        newBlockIndex: action.payload.index ?? state.blocks.length,
      };
    },
    setIsSignupModalOpen: (state, action: PayloadAction<boolean>) => {
      const { blocks, styles, editedUserFields } = state;
      saveState({ blocks, styles, editedUserFields });
      return {
        ...state,
        isSignupModalOpen: action.payload,
      };
    },
    setMousePos: (state, action: PayloadAction<{ x: number | null; y: number | null }>) => {
      return {
        ...state,
        mousePos: action.payload,
      };
    },
    setPhoneScale: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        phoneScale: action.payload,
      };
    },
    setIsImageUploadModalOpen: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isImageUploadModalOpen: action.payload,
      };
    },
    setIsSocialsPickerModalOpen: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isSocialsPickerModalOpen: action.payload,
      };
    },
    loadFromState: (state, action: PayloadAction<SavedState>) => {
      if (state.blocks.length || (!!state.styles && !isEmpty(state.styles))) {
        return;
      }
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadChanges.pending, (state, action) => {
        state.uploading = true;
      })
      .addCase(uploadChanges.fulfilled, (state, action) => {
        if (state.editedUserFields.avatar) {
          action.payload.avatar = forceImageReload(action.payload.avatar!);
        }
        return {
          ...state,
          initialBlocks: action.payload.blocks,
          blocks: action.payload.blocks,
          initialStyles: action.payload.styles,
          user: action.payload,
          editedUserFields: {},
          uploading: false,
        };
      })
      .addCase(uploadChanges.rejected, (state, action) => {
        state.uploading = false;
      })
      .addCase(getUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        return {
          ...state,
          user: action.payload,
          authChecked: true,
          loading: false,
        };
      })
      .addCase(logout.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          user: {} as User,
          styles: {},
          intialStyles: {},
          editedUserFields: {},
          blocks: [],
          intialBlocks: [],
        };
      });
  },
});

export const {
  setUser,
  updateUser,
  editUserFields,
  setAuthChecked,
  setLoading,
  updateUsername,

  setPreview,
  setSelectedBlockIndex,
  setBlocks,
  reorderBlocks,
  addBlock,
  updateBlock,
  updateSocialsBlock,
  addSocialsToSocialsBlock,
  deleteSocialsFromSocialsBlock,
  reorderSocialsForSocialsBlock,
  deleteBlock,
  resetChanges,
  initializeEditor,
  setStyles,
  setIsAddBlockModalOpen,
  setIsSignupModalOpen,
  setMousePos,
  setPhoneScale,
  setIsImageUploadModalOpen,
  setIsSocialsPickerModalOpen,
  loadFromState,
} = editorSlice.actions;

export const editorReducer = editorSlice.reducer;
export const useBlocks = () => useAppStore((state) => state.editor.blocks, shallowEqual);
export const useEditor: TypedUseSelectorHook<EditorSliceState> = (fn, equalityfn) =>
  useAppStore(({ editor }) => fn(editor), equalityfn ?? shallowEqual);
export const useEditorChanged = () =>
  useAppStore(
    (state) =>
      !equals(state.editor.blocks, state.editor.initialBlocks) ||
      !equals(state.editor.styles, state.editor.initialStyles) ||
      !isEmpty(state.editor.editedUserFields)
  );
export const useBlockFromIndex = (blockIndex: number) =>
  useAppStore((state) => state.editor.blocks[blockIndex], shallowEqual);
export const useAnalytics = () => useAppStore((state) => state.editor.user.analytics, shallowEqual);
export const useShouldOpenSignupModal = () =>
  useAppStore((state) => window.location.pathname.includes('/demo') && isEmpty(state.editor.user));
export const useIsAnyModalOpen = () =>
  useEditor(
    ({
      isAddBlockModalOpen,
      isImageUploadModalOpen,
      isSignupModalOpen,
      isSocialsPickerModalOpen,
    }) =>
      isAddBlockModalOpen || isImageUploadModalOpen || isSignupModalOpen || isSocialsPickerModalOpen
  );
