import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FunctionComponent } from 'react';

import './phone.css';
export interface PhoneProps {
  scale: number;
}

export const PHONE_HEIGHT = 812 + 54;
export const PHONE_WIDTH = 375 + 54;

const useStyles = makeStyles<Theme, PhoneProps>((theme) => ({
  phone: {
    '&.marvel-device.iphone-x': {
      transform: ({ scale }) => `scale(${scale},${scale}) translate(-50%, -50%)`,
      transformOrigin: 'top left',
      top: '50%',
      left: '50%',
      boxSizing: 'border-box !important' as 'border-box',
      width: 375 + 54,
      height: 812 + 54,
      padding: 27,
      position: 'relative',
      '& .screen': {
        boxSizing: 'border-box',
      },
      flexShrink: 0,
    },
  },
  screenContainer: {
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    MsOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    // transform: 'rotate(0)',
  },
}));

const Phone: FunctionComponent<PhoneProps> = (props) => {
  const classes = useStyles(props);
  const { children } = props;

  return (
    <div className={`marvel-device iphone-x ${classes.phone}`}>
      <div className="notch">
        <div className="camera"></div>
        <div className="speaker"></div>
      </div>
      <div className="top-bar"></div>
      <div className="sleep"></div>
      <div className="bottom-bar"></div>
      <div className="volume"></div>
      <div className="overflow">
        <div className="shadow shadow--tr"></div>
        <div className="shadow shadow--tl"></div>
        <div className="shadow shadow--br"></div>
        <div className="shadow shadow--bl"></div>
      </div>
      <div className="inner-shadow"></div>
      <div className="screen">
        <div className={`screen-container ${classes.screenContainer}`}>{children}</div>
      </div>
    </div>
  );
};

export { Phone };
