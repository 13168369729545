import { FunctionComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { updateBlock, useBlockFromIndex } from '../../../store';
import { LinkBlockDefinition } from '@brandlink/models';
import { withHttp } from '../../../utils/helpers';

export interface LinkBlockEditorProps {
  blockIndex: number;
}

const LinkBlockEditor: FunctionComponent<LinkBlockEditorProps> = ({ blockIndex }) => {
  const dispatch = useAppDispatch();

  const block = useBlockFromIndex(blockIndex) as LinkBlockDefinition;

  return (
    <>
      <TextField
        label="Title"
        fullWidth
        value={block.title}
        onChange={(e) =>
          dispatch(
            updateBlock({
              blockIndex,
              block: {
                ...block,
                title: e.target.value,
              } as LinkBlockDefinition,
            })
          )
        }
        variant="outlined"
        size="small"
        sx={{ marginBottom: 1 }}
      />
      <TextField
        label="Url"
        fullWidth
        value={block.url}
        onChange={(e) =>
          dispatch(
            updateBlock({
              blockIndex,
              block: {
                ...block,
                url: e.target.value,
              } as LinkBlockDefinition,
            })
          )
        }
        onBlur={(e) => {
          dispatch(
            updateBlock({
              blockIndex,
              block: {
                ...block,
                url: withHttp(e.target.value),
              } as LinkBlockDefinition,
            })
          );
        }}
        variant="outlined"
        size="small"
      />
    </>
  );
};

export { LinkBlockEditor };
