import React, { FunctionComponent } from 'react';
import { IconButton, InputAdornment, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import { Field, Formik } from 'formik';
import { InputBase } from 'formik-material-ui';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  adornment: {
    marginRight: 0,
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    minWidth: '100%',
    margin: '0 auto',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const UsernameInput: FunctionComponent = () => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <Formik
      initialValues={{ username: '' }}
      onSubmit={({ username }, actions) => {
        history.push(`/signup?username=${username}`);
      }}
    >
      {({ handleSubmit }) => (
        <Paper component="form" className={classes.root}>
          <Field
            component={InputBase}
            name="username"
            placeholder="yournamehere"
            className={classes.input}
            startAdornment={
              <InputAdornment className={classes.adornment} position="start">
                brandl.ink/
              </InputAdornment>
            }
          />
          <IconButton type="submit" onClick={() => handleSubmit()} className={classes.iconButton}>
            <ArrowForwardRoundedIcon />
          </IconButton>
        </Paper>
      )}
    </Formik>
  );
};

export { UsernameInput };
