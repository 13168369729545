import { makeStyles } from '@material-ui/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles((theme) => ({
  tabs: {
    overflow: 'initial',
    '& .MuiTabs-fixed': {
      overflow: 'initial !important',
    },
    '& .MuiTab-root	': {
      minWidth: 'initial',
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-end',
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      height: '1px',
      bottom: '-1px',
    },
    '& .MuiTabs-indicatorSpan': {
      width: '70%',
      backgroundColor: 'black',
    },
    '& .Mui-selected': {
      color: 'black',
      fontWeight: '500',
    },
  },
}));

export interface TabOptionsProps {
  value: any;
  handleChange: (event: any, value: any) => void;
  options: string[];
}

const TabOptions: FunctionComponent<TabOptionsProps> = ({ value, handleChange, options }) => {
  const classes = useStyles();
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      className={classes.tabs}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    >
      {options.map((option) => (
        <Tab key={option} label={option} />
      ))}
    </Tabs>
  );
};

export { TabOptions };
