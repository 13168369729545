import { Socials, SocialsBlockDefinition } from '@brandlink/models';
import { reorder } from '../../../utils/helpers';

// these bad boys must be immutable
export const getUpdatedSocialsBlock = (
  socialsBlock: SocialsBlockDefinition,
  social: Socials,
  value: string
): SocialsBlockDefinition => {
  const index = socialsBlock.socials.findIndex((socials) => socials.name === social);

  if (index === undefined) {
    return socialsBlock;
  }

  const socialsCopy = [...socialsBlock.socials];
  socialsCopy[index] = {
    name: social,
    value,
  };

  return {
    ...socialsBlock,
    socials: socialsCopy,
  };
};

export const addSocials = (
  socialsBlock: SocialsBlockDefinition,
  social: Socials
): SocialsBlockDefinition => {
  const socialsCopy = [
    ...socialsBlock.socials,
    {
      name: social,
      value: '',
    },
  ];

  return {
    ...socialsBlock,
    socials: socialsCopy,
  };
};

export const deleteSocials = (
  socialsBlock: SocialsBlockDefinition,
  social: Socials
): SocialsBlockDefinition => {
  const socialsCopy = [...socialsBlock.socials];

  return {
    ...socialsBlock,
    socials: socialsCopy.filter((socials) => socials.name !== social),
  };
};

export const reorderSocialsBlock = (
  socialsBlock: SocialsBlockDefinition,
  source: number,
  destination: number
): SocialsBlockDefinition => {
  return {
    ...socialsBlock,
    socials: reorder(socialsBlock.socials, source, destination) as any,
  };
};

export const findSocial = (socialBlock: SocialsBlockDefinition, social: Socials) => {
  return socialBlock.socials.find((socials) => socials.name === social);
};
