import React, { FunctionComponent } from 'react';

import {
  BLUE_STYLE,
  SWEET_MORNING_STYLE,
  DEEP_SPACE_STYLE,
  DARK_SKIES_STYLE,
  SUZY_STYLE,
  LITTLE_LEAF_STYLE,
} from '@brandlink/models';
import { Style } from './Style';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const styles = [
  { name: 'Blue', style: BLUE_STYLE },
  { name: 'Sweet Morning', style: SWEET_MORNING_STYLE },
  { name: 'Deep Space', style: DEEP_SPACE_STYLE },
  { name: 'Dark Skies', style: DARK_SKIES_STYLE },
  { name: 'Suzy', style: SUZY_STYLE },
  { name: 'Little Leaf', style: LITTLE_LEAF_STYLE },
];

const StylePicker: FunctionComponent = () => {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Select a style
      </Typography>
      <Grid container spacing={1}>
        {styles.map(({ name, style }) => (
          <Grid item key={name} xs={6} sm={4}>
            <Style name={name} style={style} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export { StylePicker };
