import { Socials } from '@brandlink/models';
import { SvgIcon } from '@material-ui/core';
import { ReactNode } from 'react';
import { ReactComponent as Facebook } from '../images/socials/facebook.svg';
import { ReactComponent as Youtube } from '../images/socials/youtube.svg';
import { ReactComponent as Instagram } from '../images/socials/instagram.svg';
import { ReactComponent as Snapchat } from '../images/socials/snapchat.svg';
import { ReactComponent as Twitch } from '../images/socials/twitch.svg';
import { ReactComponent as Tiktok } from '../images/socials/tiktok.svg';
import { ReactComponent as Twitter } from '../images/socials/twitter.svg';
import { ReactComponent as Linkedin } from '../images/socials/linkedin.svg';
import { ReactComponent as Patreon } from '../images/socials/patreon.svg';
import { ReactComponent as Whatsapp } from '../images/socials/whatsapp.svg';
import { ReactComponent as Behance } from '../images/socials/behance.svg';
import { ReactComponent as Dribble } from '../images/socials/dribble.svg';
import { ReactComponent as Pinterest } from '../images/socials/pinterest.svg';
import { ReactComponent as Github } from '../images/socials/github.svg';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import WebIcon from '@material-ui/icons/Web';

export interface SocialsInfo {
  prettyName: string;
  icon: ReactNode;
  placeholder?: string;

  // templates for deeplinks
  defaultUrlTemplate?: string;
  androidUrlTemplate?: string;
  iosUrlTemplate?: string;
}

export const SOCIALS: Record<Socials, SocialsInfo> = {
  [Socials.Phone]: {
    prettyName: 'Phone Number',
    icon: <PhoneIcon color="inherit" fontSize="inherit" />,
    placeholder: '+16041231234',
    defaultUrlTemplate: 'tel:{0}',
  },
  [Socials.Website]: {
    prettyName: 'Website',
    icon: <WebIcon color="inherit" fontSize="inherit" />,
    placeholder: 'https://yourwebsite.ca',
  },
  [Socials.Email]: {
    prettyName: 'Email',
    icon: <EmailIcon color="inherit" fontSize="inherit" />,
    placeholder: 'youremail@gmail.com',
    defaultUrlTemplate: 'mailto:{0}',
  },
  [Socials.Facebook]: {
    prettyName: 'Facebook',
    icon: (
      <SvgIcon fontSize="inherit">
        <Facebook />
      </SvgIcon>
    ),
    placeholder: 'https://www.facebook.com/yoururl',
  },
  [Socials.Youtube]: {
    prettyName: 'Youtube',
    icon: (
      <SvgIcon fontSize="inherit">
        <Youtube />
      </SvgIcon>
    ),
    placeholder: 'https://www.youtube.com/channel/yourchannelid',
  },

  [Socials.Instagram]: {
    prettyName: 'Instagram',
    icon: (
      <SvgIcon fontSize="inherit">
        <Instagram />
      </SvgIcon>
    ),
    placeholder: 'Your instagram username',
    defaultUrlTemplate: 'https://www.instagram.com/{0}',
  },
  [Socials.Snapchat]: {
    prettyName: 'Snapchat',
    icon: (
      <SvgIcon fontSize="inherit">
        <Snapchat />
      </SvgIcon>
    ),
    placeholder: 'Your snapchat username',
    defaultUrlTemplate: 'https://www.snapchat.com/add/{0}',
  },
  [Socials.Twitch]: {
    prettyName: 'Twitch',
    icon: (
      <SvgIcon fontSize="inherit">
        <Twitch />
      </SvgIcon>
    ),
    placeholder: 'https://twitch.tv/yourtwitchurl',
  },
  [Socials.Tiktok]: {
    prettyName: 'Tiktok',
    icon: (
      <SvgIcon fontSize="inherit">
        <Tiktok />
      </SvgIcon>
    ),
    placeholder: 'Your tiktok username',
    defaultUrlTemplate: 'https://www.tiktok.com/{0}',
  },
  [Socials.Twitter]: {
    prettyName: 'Twitter',
    icon: (
      <SvgIcon fontSize="inherit">
        <Twitter />
      </SvgIcon>
    ),
    placeholder: 'Your twitter handle',
    defaultUrlTemplate: 'https://twitter.com/{0}',
  },
  [Socials.Linkedin]: {
    prettyName: 'Linkedin',
    icon: (
      <SvgIcon fontSize="inherit">
        <Linkedin />
      </SvgIcon>
    ),
    placeholder: 'https://www.linkedin.com/in/yourlinkedinurl/',
  },
  [Socials.Patreon]: {
    prettyName: 'Patreon',
    icon: (
      <SvgIcon fontSize="inherit">
        <Patreon />
      </SvgIcon>
    ),
    placeholder: 'https://www.patreon.com/yourpatreon',
  },
  [Socials.Whatsapp]: {
    prettyName: 'Whatsapp',
    icon: (
      <SvgIcon fontSize="inherit">
        <Whatsapp />
      </SvgIcon>
    ),
    placeholder: '10000000000',
    defaultUrlTemplate: 'https://wa.me/{0}',
  },
  [Socials.Behance]: {
    prettyName: 'Behance',
    icon: (
      <SvgIcon fontSize="inherit">
        <Behance />
      </SvgIcon>
    ),
    placeholder: 'https://www.behance.net/yourbehance',
  },
  [Socials.Dribble]: {
    prettyName: 'Dribble',
    icon: (
      <SvgIcon fontSize="inherit">
        <Dribble />
      </SvgIcon>
    ),
    placeholder: 'https://dribbble.com/yourdribble',
  },
  [Socials.Pinterest]: {
    prettyName: 'Pinterest',
    icon: (
      <SvgIcon fontSize="inherit">
        <Pinterest />
      </SvgIcon>
    ),
    placeholder: 'https://www.pinterest.ca/yourpinterest',
  },
  [Socials.Github]: {
    prettyName: 'Github',
    icon: (
      <SvgIcon fontSize="inherit">
        <Github />
      </SvgIcon>
    ),
    placeholder: 'https://github.com/yourgithub',
  },
};
