import { Box, Container } from '@material-ui/core';
import { FunctionComponent } from 'react';

const CenterFullScreen: FunctionComponent = ({ children }) => {
  return (
    <Container>
      <Box
        style={{ minHeight: '100vh' }}
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          display: "flex"
        }}>
        {children}
      </Box>
    </Container>
  );
};

export { CenterFullScreen };
