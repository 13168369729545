import { BlockDefinition, BlockType } from '@brandlink/models';
import { equals } from 'ramda';
import { toast, ToastOptions } from 'react-toastify';
import { EditorSliceState } from '../store';
import { RejectValue } from '../types/RejectValue';

export const getRandomId = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const withHttp = (url: string) => {
  if (!url || url.includes('://')) return url;

  return !/^https?:\/\//i.test(url) ? `http://${url}` : url;
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)![1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const forceImageReload = (url: string) => {
  return url + '?' + Date.now();
};

export const handleErrors = (error: any, defaultMessage?: string, options?: ToastOptions) => {
  const status = error?.status || undefined;
  let apiErrors = error?.data?.errors || error?.response?.data?.errors || [];
  let errorMessages: string[] = [];

  if (status) {
    if (status === 401) {
      errorMessages.push('Not logged in. please try refreshing the page and logging back in');
    }
  }

  if (typeof error === 'string') {
    errorMessages.push(error);
  }

  if (apiErrors.length) {
    apiErrors = apiErrors.map((serverError) => serverError.msg || serverError);
    errorMessages = errorMessages.concat(apiErrors);
  }

  if (errorMessages.length) {
    errorMessages.forEach((message) => {
      toast.error(message || defaultMessage || 'Unknown error occured', options);
    });
  } else {
    toast.error(defaultMessage || 'Unknown error occured', options);
  }
};

export const generateEquals = (keys: string[]) => (obj1: any, obj2: any) => {
  return keys.reduce((acc, key) => {
    return acc && equals(obj1[key], obj2[key]);
  }, true);
};

export const getLinkBlockIds = (blocks: BlockDefinition[]): string[] => {
  return blocks
    .filter((block) => block._id && block.type === BlockType.Link)
    .map((block) => block._id!);
};

export const newEditorState = (state: EditorSliceState, rejectValue?: RejectValue) => {
  let { isSignupModalOpen } = state;
  if (rejectValue?.status === 401 && window.location.pathname === '/demo') {
    isSignupModalOpen = true;
  }
  return {
    ...state,
    isSignupModalOpen,
  };
};

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const scaleTranslation = (
  isDragging: boolean,
  translationString?: string,
  scale = 1
): string => {
  if (typeof translationString !== 'string') {
    return 'none';
  }

  if (isDragging) return translationString;

  const matches = translationString.match(/translate\((.*)px, (.*)px\)/);

  if (!matches) {
    console.warn("translation regex doesn't work");
    return translationString;
  }

  const newXTranslation = parseFloat(matches[1]) / scale;
  const newYTranslation = parseFloat(matches[2]) / scale;

  if (Number.isNaN(newXTranslation) || Number.isNaN(newYTranslation)) {
    console.warn("translation regex doesn't work");
    return translationString;
  }

  return `translate(${newXTranslation}px, ${newYTranslation}px)`;
};

export const getStylesForDraggedItem = (scale = 1): Record<string, any> => ({
  transform: `scale(${scale},${scale})`,
  width: `${(1 / scale) * 100}%`,
  transformOrigin: 'top left',
  boxSizing: 'border-box',
});

export const createDroppableIdFromBlockIndex = (type: string, blockIndex: number): string =>
  `${type}-${blockIndex}`;

export const getBlockIndexFromDroppableId = (droppableId: string): number =>
  Number(droppableId.split('-')[1]);
