import { BlockDefinition, Styles, User } from '@brandlink/models';

export interface SavedState {
  blocks: BlockDefinition[];
  styles?: Styles;
  editedUserFields: Partial<User>;
}

export const saveState = ({ blocks, styles, editedUserFields }: SavedState) => {
  localStorage.setItem(
    'editorState',
    JSON.stringify({
      blocks,
      styles,
      editedUserFields,
    })
  );
};

export const clearState = () => {
  localStorage.removeItem('editorState');
};

export const getState = (): SavedState | undefined => {
  const savedFields = localStorage.getItem('editorState');
  if (!savedFields) return undefined;
  return JSON.parse(savedFields);
};
