import { FunctionComponent } from 'react';

import { VideoBlockDefinition, Styles } from '@brandlink/models';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ReactPlayer from 'react-player';

import { BLUE_STYLE } from '@brandlink/models';
import { isEmpty } from 'ramda';

export interface VideoBlockProps {
  block: VideoBlockDefinition;
  styles?: Styles;
  noStart?: boolean;
}

const useStyles = makeStyles<Theme, Styles>((theme) => ({
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

const VideoBlock: FunctionComponent<VideoBlockProps> = ({ block, styles, noStart }) => {
  const classes = useStyles(isEmpty(styles ?? {}) ? BLUE_STYLE : styles!);

  if (!block.url) return null;

  return (
    <div className={classes.playerWrapper}>
      <ReactPlayer
        className={classes.reactPlayer}
        url={block.url}
        width="100%"
        height="100%"
        controls
        light={noStart}
        onClickCapture={(event) => {
          // STOP AUTOPLAY
          if (noStart) event.stopPropagation();
        }}
      />
    </div>
  );
};

export { VideoBlock };
