import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { FunctionComponent, useState } from 'react';
import { blockConfig } from '../../config/blockConfig';
import { useAppDispatch } from '../../hooks/useAppStore';
import {
  setMousePos,
  setSelectedBlockIndex,
  useBlockFromIndex,
  useEditor,
  useIsAnyModalOpen,
} from '../../store';
import { makeStyles } from '@material-ui/styles';
import { deleteBlock } from '../../store/';
import CancelIcon from '@material-ui/icons/Cancel';
import { getStylesForDraggedItem, scaleTranslation } from '../../utils/helpers';

const useStyles = makeStyles(() => ({
  deleteContainer: {
    position: 'relative',
    '&:hover $cancelIcon': {
      display: 'block',
    },
    '&:focus $cancelIcon': {
      display: 'block',
    },
  },
  cancelIcon: {
    display: 'none',
    opacity: '0.7',
    fontSize: '30px',
    color: 'red',
    position: 'absolute',
    top: -10,
    right: -10,
    zIndex: 1,
  },
}));

const getItemStyle = (isDragging, draggableStyle, scale) => {
  const transform = scaleTranslation(isDragging, draggableStyle.transform, scale);
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    boxSizing: 'border-box',
    // styles we need to apply on draggables
    ...draggableStyle,
    transform,
    paddingBottom: '8px',
  };
};

export interface DraggableBlockProps {
  blockIndex: number;
  scale: number;
  snapshot: any;
  provided: any;
  rubric: any;
}
const DraggableBlock: FunctionComponent<DraggableBlockProps> = ({
  blockIndex,
  scale,
  snapshot,
  provided,
  rubric,
}) => {
  const classes = useStyles();
  const preview = useEditor((editor) => editor.preview);
  const styles = useEditor((editor) => editor.styles);
  const block = useBlockFromIndex(blockIndex);
  const isAnyModalOpen = useIsAnyModalOpen();

  const [editing, setEditing] = useState(false);
  const dispatch = useAppDispatch();

  if (preview) {
    let BlockComponent = blockConfig[block.type].mainComponent;
    return (
      <Box sx={{ mb: 1 }}>
        <BlockComponent block={block} styles={styles} />
      </Box>
    );
  }

  const VisualEditorComponent = blockConfig[block.type].visualEditorComponent;
  if (!VisualEditorComponent) {
    console.warn('Invalid block', block);
    return null;
  }

  const handleClickAway = () => {
    if (isAnyModalOpen) return;
    setEditing(false);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleDoubleClick = () => {
    if (!editing) {
      setEditing(true);
    }
  };

  const handleRightClick = (event) => {
    event.preventDefault();
    dispatch(setSelectedBlockIndex(blockIndex));
    dispatch(
      setMousePos({
        x: event.clientX - 2,
        y: event.clientY - 4,
      })
    );
  };

  const handleDelete = async () => {
    await dispatch(deleteBlock(blockIndex));
  };
  return (
    <div
      className={classes.deleteContainer}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, scale)}
    >
      <div style={snapshot.isDragging ? getStylesForDraggedItem(scale) : {}}>
        <ClickAwayListener
          onClickAway={handleClickAway}
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
        >
          <div
            onDoubleClick={handleDoubleClick}
            onClick={handleClick}
            onContextMenu={handleRightClick}
          >
            <CancelIcon
              className={classes.cancelIcon}
              onClick={() => {
                handleDelete();
              }}
            />
            <VisualEditorComponent
              editing={editing}
              isDragging={snapshot.isDragging}
              block={block as any}
              blockIndex={blockIndex}
            />
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

export { DraggableBlock };
