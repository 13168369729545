import React, { FunctionComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import { deleteSocialsFromSocialsBlock, updateSocialsBlock, useEditor } from '../../../store';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { makeStyles } from '@material-ui/styles';
import { Socials, SocialsBlockDefinition } from '@brandlink/models';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SOCIALS } from '../../../config/socialsConfig';
import { findSocial } from './socialsBlockUtils';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
  icon: { fontSize: '1.5rem', color: '#000000' },
}));

export interface SocialsTextFieldProps {
  social: Socials;
  blockIndex: number;
}

export const SocialsTextField: FunctionComponent<SocialsTextFieldProps> = ({
  blockIndex,
  social,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const value = useEditor((editor) => {
    return findSocial(editor.blocks[blockIndex] as SocialsBlockDefinition, social)?.value ?? '';
  });
  const socialObject = SOCIALS[social];

  const onChange = (e) =>
    dispatch(
      updateSocialsBlock({
        blockIndex,
        social,
        value: e.target.value,
      })
    );

  const onDelete = () => {
    dispatch(deleteSocialsFromSocialsBlock({ blockIndex, social }));
  };

  return (
    <>
      <TextField
        label={socialObject.prettyName}
        fullWidth
        value={value ?? ''}
        placeholder={socialObject.placeholder}
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.icon}>
              {socialObject.icon}
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton onClick={onDelete} size="small">
              <DeleteIcon />
            </IconButton>
          ),
        }}
        multiline
        variant="outlined"
        size="small"
        sx={{ marginBottom: 1 }}
      />
    </>
  );
};
