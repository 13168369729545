import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.grey[100],
  },
}));

const Feedback: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        boxSizing: 'border-box',
        p: 3,
      }}
    ></Box>
  );
};

export { Feedback };
