import { FunctionComponent, JSXElementConstructor, ReactElement } from 'react';
import { useDnd, useEditor } from '../../store';

export interface PlaceholderProps {
  droppableId: string;
  placeholder?: ReactElement<HTMLElement, string | JSXElementConstructor<any>> | null | undefined;
  applyScale?: boolean;
}

// use this class in droppables that could be in the visual phone editor (DndEditor)
export const Placeholder: FunctionComponent<PlaceholderProps> = ({
  droppableId,
  placeholder,
  applyScale,
}) => {
  const placeholderHeight = useDnd(({ placeholderHeight }) => placeholderHeight);
  const placeholderWidth = useDnd(({ placeholderWidth }) => placeholderWidth);
  const destinationDroppableId = useDnd(({ destinationDroppableId }) => destinationDroppableId);
  const scale = useEditor(({ phoneScale }) => phoneScale);

  if (destinationDroppableId !== droppableId || placeholderHeight === 0) {
    return placeholder ?? null;
  }

  return (
    <>
      <div
        style={{
          height: applyScale ? placeholderHeight / scale : placeholderHeight,
          width: placeholderWidth,
        }}
      />
      <div style={{ display: 'none' }}>{placeholder}</div>
    </>
  );
};
