import 'react-toastify/dist/ReactToastify.css';
import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './shared/Layout';
import PrivateRoute from './shared/PrivateRoute';
import Admin from './components/Admin/Admin';
import { useAppDispatch } from './hooks/useAppStore';
import { toast, ToastContainer } from 'react-toastify';
import ProfileLayout from './shared/ProfileLayout';
import { User } from '@brandlink/models';
import { getUser, initializeEditor, loadFromState, useEditor } from './store';
import { isEmpty } from 'ramda';
import { Helmet } from 'react-helmet';
import { getState } from './utils/localstorageUtils';
import { Affiliates, Api, Contribute, Home, Login, NotFound, Profile, Register } from './pages';

export interface IRoute {
  path?: string;
  component: FunctionComponent;
  layout?: FunctionComponent;
  route?: FunctionComponent<any>;
  exact?: boolean;
}

const routes: IRoute[] = [
  {
    path: '/',
    component: Home,
    layout: Layout,
    exact: true,
  },
  {
    path: '/signup',
    component: Register,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/profile',
    component: Admin,
    route: PrivateRoute,
    layout: ProfileLayout,
  },
  {
    path: '/demo',
    component: Admin,
    layout: ProfileLayout,
  },
  {
    path: '/affiliates',
    component: Affiliates,
    layout: Layout,
  },
  {
    path: '/contribute',
    component: Contribute,
    layout: Layout,
  },
  {
    path: '/api/*',
    component: Api,
  },
  {
    path: '/:username',
    component: Profile,
    exact: true,
  },
  {
    component: NotFound,
    layout: Layout,
  },
];

function App() {
  const dispatch = useAppDispatch();
  const loading = useEditor((editor) => editor.loading);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = (await dispatch(getUser())).payload as User;
        if (!isEmpty(user)) {
          await dispatch(
            initializeEditor({
              blocks: user.blocks,
              styles: user.styles,
            })
          );
        }
      } catch (err) {
        toast.error('Could not fetch user');
      }

      // try to load state after
      const savedState = getState();
      if (savedState) {
        await dispatch(loadFromState(savedState));
      }
    };

    fetchUser();
  }, [dispatch]);

  if (loading) {
    // return <LoadingScreen />;
  }

  return (
    <>
      <Helmet>
        <title>Brandlink - A website link built for you and your audience</title>
        <meta
          name="description"
          content="Build your own site with a stunning user interface, easily add and manage content from YouTube, Tiktok, Instagram, and more. Your personalized link can be used and given out anywhere."
        />
      </Helmet>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} limit={3} />
      <Router>
        <Switch>
          {routes.map((route, i) => {
            const Layout = route.layout ?? Fragment;
            const TheRoute = route.route ?? Route;
            return (
              <TheRoute path={route.path} exact={route.exact} key={i}>
                <Layout>
                  <route.component />
                </Layout>
              </TheRoute>
            );
          })}
        </Switch>
      </Router>
    </>
  );
}

export default App;
