import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getUser, initializeEditor } from '../store/';
import { useAppDispatch } from '../hooks/useAppStore';
import { User } from '@brandlink/models';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { handleErrors } from '../utils/helpers';
import { GoogleLogin } from '../components/ui/GoogleLogin';
import { Separator } from '../components/ui/Separator';
import Link from '@material-ui/core/Link';
import demoVideo from '../images/demo.mp4';
import { Hidden } from '../components/ui/Hidden';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },

  paper: {
    padding: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    background: '#F9F8F9',
  },
  video: {
    objectFit: 'cover',
    position: 'fixed',
    height: '100%',
    width: 'auto',
  },
}));

export interface LoginFormValues {
  email: string;
  username?: string;
  password: string;
}

export const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const query = new URLSearchParams(useLocation().search);
  const username = query.get('username') ?? undefined;

  const initialValues: LoginFormValues = {
    email: '',
    password: '',
    username,
  };

  const handleSubmit = async (values: LoginFormValues) => {
    try {
      const response = await axios.post('/api/auth/login', values);
      if (response.status === 200) {
        const user = (await dispatch(getUser())).payload as User;
        await dispatch(
          initializeEditor({
            blocks: user.blocks,
            styles: user.styles,
          })
        );
        history.push('/profile');
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        toast.error('Invalid Email or Password', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        handleErrors(err, "Couldn't login, please try again later");
      }
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Hidden down="md">
        <Grid className={classes.videoContainer} item xs={false} sm={8} md={5}>
          <video className={classes.video} autoPlay muted loop id="myVideo">
            <source src={demoVideo} type="video/mp4" />
          </video>
        </Grid>
      </Hidden>
      <Grid
        className={classes.paper}
        item
        xs={12}
        sm={12}
        md={7}
        component={Paper}
        elevation={6}
        square
      >
        <Typography variant="body1" className={classes.title}>
          LOG IN
        </Typography>
        <Typography component="h1" variant="h4">
          Welcome back
        </Typography>
        <Typography variant="body1">Log in to manage your account</Typography>
        <Typography sx={{ mb: 3, mt: 1 }} variant="body1">
          Don't have an account?{' '}
          <Link component={RouterLink} to="/signup">
            Sign up
          </Link>{' '}
          to get started
        </Typography>
        <GoogleLogin />
        <Separator>or</Separator>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleSubmit, isSubmitting }) => (
            <Form className={classes.form}>
              <Typography variant="subtitle2">Enter your email</Typography>
              <Field
                className={classes.textField}
                component={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Typography variant="subtitle2">Enter your password</Typography>
              <Field
                className={classes.textField}
                component={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
              />
              <Grid container>
                <Grid item xs>
                  <Link to="#" component={RouterLink}>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    className={classes.submit}
                    size="large"
                  >
                    Log in
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
