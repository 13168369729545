import { CircularProgress } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { CenterFullScreen } from './CenterFullScreen';

const LoadingScreen: FunctionComponent = () => {
  return (
    <CenterFullScreen>
      <CircularProgress />
    </CenterFullScreen>
  );
};

export { LoadingScreen };
