import { InputBase, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import { BLUE_STYLE, Styles, VideoBlockDefinition } from '@brandlink/models';
import { updateBlock, useEditor } from '../../../store/editorSlice';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { VideoBlock } from './VideoBlock';
import Paper from '@material-ui/core/Paper';
import { isEmpty } from 'ramda';

const useStyles = makeStyles<Theme, Styles>((theme) => ({
  inputBase: {
    color: 'inherit',
    padding: 0,
    '& .MuiInputBase-input': {
      padding: 0,
      lineHeight: 1.5,
      height: 'inherit',
      textAlign: 'center',
    },
  },
  container: {
    padding: '17px 0',
    textAlign: 'center',
  },
  wrap: {
    overflowWrap: 'anywhere',
  },
}));

export interface VideoBlockEditorProps {
  block: VideoBlockDefinition;
  isDragging: boolean;
  blockIndex: number;
  editing: boolean;
}

const VideoBlockVisualEditor: FunctionComponent<VideoBlockEditorProps> = React.memo(
  ({ block, isDragging, blockIndex, editing }) => {
    const dispatch = useAppDispatch();
    const styles = useEditor((editor) => editor.styles);
    const classes = useStyles(isEmpty(styles ?? {}) ? BLUE_STYLE : styles!);

    const { url } = block;

    return (
      <div className={clsx(isDragging && classes.dragging, editing && classes.editing)}>
        {editing ? (
          <Paper elevation={0} className={classes.container}>
            <InputBase
              className={classes.inputBase}
              placeholder="Video Url"
              value={url}
              onChange={(e) =>
                dispatch(
                  updateBlock({
                    blockIndex,
                    block: {
                      ...block,
                      url: e.target.value,
                    } as VideoBlockDefinition,
                  })
                )
              }
              fullWidth
            />
          </Paper>
        ) : !url ? (
          <Paper className={classes.container} elevation={0}>
            Double click to add a video URL
          </Paper>
        ) : (
          <VideoBlock noStart block={block} styles={styles} />
        )}
      </div>
    );
  }
);

export { VideoBlockVisualEditor };
