import React, { FunctionComponent } from 'react';
import ReactFrappeChart from 'react-frappe-charts';

export interface DataSet {
  values: number[];
  name: string;
}

export interface OverviewChartProps {
  labels: string[];
  dataSets: DataSet[];
}

export const OverviewChart: FunctionComponent<OverviewChartProps> = ({ labels, dataSets }) => {
  if (!dataSets) return null;
  return (
    <ReactFrappeChart
      type="line"
      colors={['#256EFF', '#21ba45']}
      axisOptions={{ xAxisMode: 'tick', yAxisMode: 'tick', xIsSeries: 1 }}
      lineOptions={{ hideDots: 1, regionFill: 1 }}
      height={250}
      data={{
        labels: labels,
        datasets: dataSets,
      }}
    />
  );
};
