import { FunctionComponent } from 'react';

import { SocialsBlockDefinition, Styles } from '@brandlink/models';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { BLUE_STYLE } from '@brandlink/models';
import { SOCIALS } from '../../../config/socialsConfig';
import { isEmpty } from 'ramda';
import { stringFormat } from '../../../utils/stringUtils';

export interface SocialsBlockProps {
  block: SocialsBlockDefinition;
  styles?: Styles;
  inVisualEditor?: boolean;
}

const useStyles = makeStyles<Theme, Styles>((theme) => ({
  container: {},
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  social: {
    borderRadius: '50%',
    background: '#ffffff',
    fontSize: '26px',
    padding: '9px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
    '&:not(:last-child)': {
      marginRight: '5px',
    },
  },
}));

const SocialsBlock: FunctionComponent<SocialsBlockProps> = ({ block, styles, inVisualEditor }) => {
  const classes = useStyles(isEmpty(styles ?? {}) ? BLUE_STYLE : styles!);

  if (!block.socials.length) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        {block.socials
          .filter(({ value }) => inVisualEditor || value)
          .map(({ name, value }) => {
            const social = SOCIALS[name];
            return inVisualEditor ? (
              <div key={name} className={classes.social}>
                {social.icon}
              </div>
            ) : (
              <a
                key={name}
                className={classes.social}
                href={
                  social.defaultUrlTemplate ? stringFormat(social.defaultUrlTemplate, value) : value
                }
                target="_blank"
                rel="noreferrer"
              >
                {social.icon}
              </a>
            );
          })}
      </div>
    </div>
  );
};

export { SocialsBlock };
