import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from 'ramda';
import React, { FunctionComponent } from 'react';
import { useAnalytics } from '../../../store';
import { AnalyticsUtils } from '../../../utils/analyticsUtils';
import { TabOptions } from '../../ui/TabOptions';
import { AnalyticsPlaceholder } from './AnalyticsPlaceholder';
import { OverviewChart } from './OverviewChart';
import { PercentChange } from './PercentChange';

const useStyles = makeStyles((theme) => ({
  card: { ...theme.card, maxWidth: undefined },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  overview: {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'center',
  },
  overviewItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
  },
  overviewItemInner: {
    display: 'flex',
    alignItems: 'center',
    '& h4': {
      paddingRight: '5px',
    },
  },
}));

export interface AnalyticsCardProps {}

export const AnalyticsSummaryCard: FunctionComponent<AnalyticsCardProps> = () => {
  const classes = useStyles();
  const [option, setOption] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setOption(newValue);
  };

  const analytics = useAnalytics();

  if (isEmpty(analytics ?? {})) {
    return <AnalyticsPlaceholder />;
  }

  const {
    viewValues,
    clickValues,
    labels,
    totalViews,
    prevTotalViews,
    totalClicks,
    prevTotalClicks,
  } = AnalyticsUtils.getMemoizedAnalyzedSummary(analytics!, option);

  return (
    <Paper className={classes.card}>
      <div className={classes.header}>
        <Typography component="h2" variant="h5">
          Overview
        </Typography>
        <TabOptions
          value={option}
          handleChange={handleChange}
          options={['Week', 'Month', 'Year', 'Lifetime']}
        />
      </div>
      <div className={classes.overview}>
        <div className={classes.overviewItem}>
          <Typography variant="subtitle2">Total Views</Typography>
          <div className={classes.overviewItemInner}>
            <Typography variant="h4">{totalViews}</Typography>
            <PercentChange initial={prevTotalViews} final={totalViews} />
          </div>
        </div>
        <div className={classes.overviewItem}>
          <Typography variant="subtitle2">Total Clicks</Typography>
          <div className={classes.overviewItemInner}>
            <Typography variant="h4">{totalClicks}</Typography>
            <PercentChange initial={prevTotalClicks} final={totalClicks} />
          </div>
        </div>
        <div className={classes.overviewItem}>
          <Typography variant="subtitle2">Click Through Rate</Typography>
          <div className={classes.overviewItemInner}>
            <Typography variant="h4">{((totalClicks / totalViews) * 100).toFixed(1)}%</Typography>
            <PercentChange
              initial={prevTotalClicks / prevTotalViews}
              final={totalClicks / totalViews}
            />
          </div>
        </div>
      </div>
      <OverviewChart
        labels={labels}
        dataSets={[
          { name: 'Clicks', values: clickValues },
          { name: 'Views', values: viewValues },
        ]}
      />
    </Paper>
  );
};
