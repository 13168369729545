import React, { FunctionComponent, useEffect } from 'react';
import { Container, Typography, Box, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../hooks/useAppStore';
import { getProfile, useProfile } from '../store/profileSlice';
import { LoadingScreen } from '../shared/LoadingScreen';
import { CenterFullScreen } from '../shared/CenterFullScreen';
import { isEmpty } from 'ramda';
import { BLUE_STYLE, Styles } from '@brandlink/models';
import defaultProfileImage from '../images/profile.png';
import { Theme } from '@material-ui/core/styles';
import { blockConfig } from '../config/blockConfig';

const useStyles = makeStyles<Theme, Styles>((theme) => ({
  blockWrapper: {
    margin: '8px 0',
  },
  container: {
    background: ({ background }) => background?.style,
    padding: '20px 0',
    minHeight: '100vh',
    boxSizing: 'border-box',
  },
  innerWrapper: {
    maxWidth: 700,
  },
  avatar: { width: 96, height: 96 },
}));

export const Profile: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { _id, blocks, bio, title, loading, notFound, avatar, styles } = useProfile();
  const { username } = useParams<{ username: string }>();
  const classes = useStyles(isEmpty(styles ?? {}) ? BLUE_STYLE : styles!);

  useEffect(() => {
    dispatch(getProfile(username));
  }, [dispatch, username]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (notFound) {
    return (
      <CenterFullScreen>
        <Typography variant="h4">Profile '{username}' not found</Typography>
      </CenterFullScreen>
    );
  }

  return (
    <div className={classes.container}>
      <Container className={classes.innerWrapper}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'white',
          }}
        >
          <Avatar className={classes.avatar} src={avatar || defaultProfileImage} />
          {(title || username) && (
            <Typography variant="body3" component="p">
              {title || username}
            </Typography>
          )}
          {bio && (
            <Typography variant="body3" component="p">
              {bio}
            </Typography>
          )}
        </Box>
        {blocks.map((block, i) => {
          const Component = blockConfig[block.type].mainComponent;
          const createHandleAnalyticsEvent = blockConfig[block.type].createHandleAnalyticsEvent;

          const handleAnalyticsEvent = createHandleAnalyticsEvent
            ? createHandleAnalyticsEvent(_id, block)
            : undefined;

          if (!Component) {
            console.warn('Invalid block', block);
            return null;
          }
          return (
            <div key={i} className={classes.blockWrapper}>
              <Component
                block={block as any}
                styles={styles}
                handleAnalyticsEvent={handleAnalyticsEvent}
              />
            </div>
          );
        })}
      </Container>
    </div>
  );
};
