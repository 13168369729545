import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { Editor } from '../Editor/Editor';
import { Analytics } from '../Analytics/Analytics';
import { Feedback } from '../Feedback/Feedback';
import { Appearance } from '../Appearance/Appearance';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

export const AdminTabs: FunctionComponent = () => {
  let match = useRouteMatch();

  return (
    <Box
      sx={{
        height: '100%',
        overflowY: 'scroll',
        scrollBehavior: 'smooth',
        background: '#f5f5f5',
      }}
      id="admin-main-container"
    >
      <Box sx={{ py: 3 }}>
        <Switch>
          <Route exact path={`${match.path}/appearance`}>
            <Appearance />
          </Route>
          <Route exact path={`${match.path}/analytics`}>
            <Analytics />
          </Route>
          <Route exact path={`${match.path}/feedback`}>
            <Feedback />
          </Route>
          <Route path={`${match.path}/`}>
            <Editor />
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};
