import React, { FunctionComponent } from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import mainSVG from '../images/undraw_insert_block_re_4t4l.svg';
import buildSVG from '../images/undraw_Post_online_re_1b82.svg';
import socialMediaSVG from '../images/undraw_Social_media_re_w12q.svg';
import analyticsSVG from '../images/undraw_google_analytics_a57d.svg';
import paymentsSVG from '../images/undraw_wallet_aym5.svg';

import TimerIcon from '@material-ui/icons/Timer';
import LinkIcon from '@material-ui/icons/Link';
import BarChartIcon from '@material-ui/icons/BarChart';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Avatar from '@material-ui/core/Avatar';
import { Hidden } from '../components/ui/Hidden';
import { UsernameInput } from '../components/UsernameInput';
import { Arrow } from '../components/Arrow/Arrow';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  hero: {
    paddingTop: 20,
    paddingBottom: 100,
    position: 'relative',
    zIndex: 2,
  },
  heroSection: {
    paddingTop: '55px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  section: {
    padding: '75px 0',
  },
  greySection: {
    background: '#F7F9FC',
    position: 'relative',
  },
  divider: {
    position: 'absolute',
    width: '100%',
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    height: '35%',
  },
  textContainer: {
    display: 'flex',
    '& > *': {
      marginRight: '12px',
    },
  },
  avatar: {
    width: '48px',
    height: '48px',
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
  },
  primary: {
    color: theme.palette.primary.main,
  },
}));

export const Home: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <>
      <section className={clsx(classes.greySection, classes.heroSection)}>
        <Container maxWidth="lg">
          <Grid className={classes.hero} container spacing={5}>
            <Grid item sm={12} md={7}>
              <Typography variant="h2">A website built for you and your audience</Typography>
              <Typography variant="h6">Share your brand and content with just one link</Typography>
              <Box
                sx={{
                  my: 3,
                }}
              >
                <Typography variant="h4" display="inline">
                  Signup for FREE!
                </Typography>
                <Arrow />
              </Box>
              <UsernameInput />
              <Box sx={{ textAlign: 'center', mt: 1 }}>
                <Typography variant="body1">or</Typography>
                <Button variant="contained" component={Link} to="/demo" sx={{ mt: 1 }}>
                  Try the demo
                </Button>
              </Box>
            </Grid>
            <Hidden down="sm">
              <Grid item sm={12} md={5} style={{ textAlign: 'center' }}>
                <img src={mainSVG} alt="logo" className="svg" style={{ maxWidth: 500 }} />
              </Grid>
            </Hidden>
          </Grid>
        </Container>
        <svg
          className={classes.divider}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1921 273"
        >
          <polygon fill="#fff" points="0,273 1921,273 1921,0 "></polygon>
        </svg>
      </section>
      <Container maxWidth="lg">
        <Grid container spacing={10} className={classes.section}>
          <Grid item sm={12} md={4} style={{ textAlign: 'center' }}>
            <img style={{ maxWidth: 200 }} src={buildSVG} alt="logo" className="svg" />
          </Grid>
          <Grid item sm={12} md={8} className={classes.textContainer}>
            <Avatar className={classes.avatar} variant="green">
              <TimerIcon />
            </Avatar>
            <div>
              <Typography variant="h4">
                Get started in under <span className={classes.primary}>5 minutes</span>
              </Typography>
              <Typography variant="body1">
                Easily managed. Utilize our easy and intuitive drag and drop editor to create what
                you want, and look how you want it.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid direction="row-reverse" container spacing={10} className={classes.section}>
          <Grid item sm={12} md={4}>
            <img src={socialMediaSVG} alt="logo" className="svg" />
          </Grid>
          <Grid item sm={12} md={8} className={classes.textContainer}>
            <Avatar className={classes.avatar} variant="blue">
              <LinkIcon />
            </Avatar>
            <div>
              <Typography variant="h4">
                <span className={classes.primary}>Connect</span> your followers
              </Typography>
              <Typography variant="body1">
                Customize your site. Brandlink allows you to add content from YouTube, Tiktok,
                Instagram, and more.
              </Typography>
              <Typography variant="body1">
                Share what you love. Your personalized link can be used and given out anywhere.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={10} className={classes.section}>
          <Grid item sm={12} md={4}>
            <img src={analyticsSVG} alt="logo" className="svg" />
          </Grid>
          <Grid item sm={12} md={8} className={classes.textContainer}>
            <Avatar className={classes.avatar} variant="yellow">
              <BarChartIcon />
            </Avatar>
            <div>
              <Typography variant="h4">
                Collect Powerful <span className={classes.primary}>analytics</span>
              </Typography>
              <Typography variant="body1">
                Keep track of your visitors. Know which links are getting the most attention and
                clicks.
              </Typography>
              <Typography variant="body1">
                Get comprehensive reports. Measure key metrics such as engagement and click through
                rates
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid direction="row-reverse" container spacing={10} className={classes.section}>
          <Grid item sm={12} md={4}>
            <img src={paymentsSVG} alt="logo" className="svg" />
          </Grid>
          <Grid item sm={12} md={8} className={classes.textContainer}>
            <Avatar className={classes.avatar} variant="red">
              <LocalAtmIcon />
            </Avatar>
            <div>
              <Typography variant="h4">
                Take <span className={classes.primary}>payments</span> and build your business
              </Typography>
              <Typography variant="body1">
                Begin monetizing your content. Build your business and set up payments in under 5
                minutes
              </Typography>
              <Typography variant="body1">
                Let your visitors support you. Your personalized link can be used and given out
                anywhere.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
