import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { isEmpty } from 'ramda';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Hidden } from '../components/ui/Hidden';
import { useAppDispatch } from '../hooks/useAppStore';
import { logout, useEditor } from '../store';

const useStyles = makeStyles((theme) => ({
  navbar: {
    zIndex: 1000,
    background: 'white',
    boxShadow: 'rgb(140 152 164 / 25%) 0px 3px 6px 0px',
    '& .MuiToolbar-root': {
      '& > *:not(:last-child)': {
        marginRight: '12px',
      },
    },
    color: '#333',
  },
  logo: {
    color: 'inherit',
    textDecoration: 'none',
    marginRight: '35px!important',
  },
  links: {
    color: 'inherit',
    textDecoration: 'none',
  },
  mobileToolbar: {
    display: 'flex',
    justifyContent: 'space-around',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: 'rgb(244, 244, 244)',
    minHeight: '45px',
  },
}));

const HideOnScroll: FunctionComponent = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children as React.ReactElement}
    </Slide>
  );
};

export const Navbar: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const user = useEditor((editor) => editor.user);
  const authChecked = useEditor((editor) => editor.authChecked);

  return (
    <HideOnScroll>
      <AppBar className={classes.navbar} color="transparent" elevation={0}>
        <Toolbar>
          <Link className={classes.logo} to="/">
            <Typography variant="h5">BrandLink</Typography>
          </Link>
          <Hidden down="sm">
            <Link className={classes.links} to="/contribute">
              Contribute
            </Link>
            <Link className={classes.links} to="/affiliates">
              Affiliates
            </Link>
          </Hidden>
          <Box
            sx={{
              flexGrow: 1,
            }}
          ></Box>
          {authChecked ? (
            !user || isEmpty(user) ? (
              <>
                <Button component={Link} to="/login" color="inherit">
                  Login
                </Button>
                <Button component={Link} to="/signup" variant="contained" color="primary">
                  Sign up FREE
                </Button>
              </>
            ) : (
              <>
                <Button component={Link} to="/profile" variant="contained" color="primary">
                  Profile
                </Button>
                <Button onClick={() => dispatch(logout())} color="inherit">
                  Logout
                </Button>
              </>
            )
          ) : null}
        </Toolbar>
        <Hidden up="sm">
          <Toolbar className={classes.mobileToolbar}>
            <Link className={classes.links} to="/contribute">
              Contribute
            </Link>
            <Link className={classes.links} to="/affiliates">
              Affiliates
            </Link>
          </Toolbar>
        </Hidden>
      </AppBar>
    </HideOnScroll>
  );
};
