import { LinkBlockDefinition } from '@brandlink/models';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { useAnalytics, useEditor } from '../../../store';
import { AnalyticsUtils } from '../../../utils/analyticsUtils';
import { TabOptions } from '../../ui/TabOptions';
import { OverviewChart } from './OverviewChart';
import { PercentChange } from './PercentChange';

const useStyles = makeStyles((theme) => ({
  card: { ...theme.card, maxWidth: undefined },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  overview: {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'center',
  },
  overviewItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
  },
  overviewItemInner: {
    display: 'flex',
    alignItems: 'center',
    '& h4': {
      paddingRight: '5px',
    },
  },
}));

export interface AnalyticsCardProps {
  blockId: string;
}

export const AnalyticsCard: FunctionComponent<AnalyticsCardProps> = ({ blockId }) => {
  const classes = useStyles();
  const [option, setOption] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setOption(newValue);
  };

  const analytics = useAnalytics();
  const user = useEditor((editor) => editor.user);

  const { total, prevTotal, values } = AnalyticsUtils.analyzeBlock(analytics!, blockId, option);
  const labels = AnalyticsUtils.getLabels(analytics!, option);

  const block = user.blocks.find((block) => block._id === blockId) as LinkBlockDefinition;

  return (
    <Paper className={classes.card}>
      <div className={classes.header}>
        <Typography component="h2" variant="h5">
          {`${block.title}`}
        </Typography>
        <TabOptions
          value={option}
          handleChange={handleChange}
          options={['Week', 'Month', 'Year', 'Lifetime']}
        />
      </div>
      <div className={classes.overview}>
        <div className={classes.overviewItem}>
          <Typography variant="subtitle2">Total Clicks</Typography>
          <div className={classes.overviewItemInner}>
            <Typography variant="h4">{total}</Typography>
            <PercentChange initial={prevTotal} final={total} />
          </div>
        </div>
      </div>
      <OverviewChart labels={labels} dataSets={[{ name: 'views', values: values }]} />
    </Paper>
  );
};
