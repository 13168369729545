import { BlockDefinition, BlockType } from '@brandlink/models';
import { Fade, IconButton, Paper, Popper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import React, { FunctionComponent, useState } from 'react';
import { addBlock } from '../store/';
import { useAppDispatch } from './useAppStore';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    pointerEvents: 'auto',
  },
  typography: {
    padding: theme.spacing(1),
  },
  addButton: {
    borderRadius: '50%',
  },
}));

const usePopover = (blockIndex: number) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      setAnchorEl((prev) => {
        if (prev) {
          setShow(true);
        }
        return prev;
      });
    }, 400);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setShow(false);
  };

  const handleAdd = () => {
    dispatch(
      addBlock({
        blockIndex: blockIndex + 1,
        block: {
          type: BlockType.Link,
          title: '',
          url: '',
        } as Partial<BlockDefinition>,
      })
    );
    handlePopoverClose();
  };

  const classes = useStyles();

  const Component: FunctionComponent = () => (
    <Popper
      className={classes.popover}
      open={open && show}
      anchorEl={anchorEl}
      placement="bottom"
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper className={classes.addButton}>
            <IconButton onClick={handleAdd} color="primary">
              <AddIcon />
            </IconButton>
          </Paper>
        </Fade>
      )}
    </Popper>
  );

  return {
    Component,
    handlePopoverOpen,
    handlePopoverClose,
  };
};

export { usePopover };
