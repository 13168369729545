import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AnalyticsSummaryCard } from './AnalyticsSummaryCard';
import { getLinkBlockIds } from '../../../utils/helpers';
import { AnalyticsCard } from './AnalyticsCard';
import { isEmpty } from 'ramda';
import { useEditor } from '../../../store';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  container: {
    background: grey[100],
  },
}));

const Analytics: FunctionComponent = () => {
  const classes = useStyles();
  const user = useEditor((editor) => editor.user);

  const hasAnalytics = !isEmpty(user.analytics ?? {});

  const linkBlockIds = getLinkBlockIds(user.blocks ?? []);

  return (
    <Box
      className={classes.container}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        boxSizing: 'border-box',
        p: 3,
      }}
    >
      <AnalyticsSummaryCard />
      {hasAnalytics && linkBlockIds.map((linkBlockId) => <AnalyticsCard blockId={linkBlockId} />)}
    </Box>
  );
};

export { Analytics };
