import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { dndReducer, editorReducer, profileReducer } from '.';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV === 'production'
      ? getDefaultMiddleware()
      : getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    profile: profileReducer,
    editor: editorReducer,
    dnd: dndReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
