import { FunctionComponent } from 'react';
import { Hidden } from '../../ui/Hidden';
import { makeStyles } from '@material-ui/styles';
import { DesktopChangeFabs } from './DesktopChangeFabs';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: '0',
    right: '0',
  },
  mobilePreviewFab: {
    position: 'relative',
    bottom: '64px',
    right: '8px',
  },
}));

export const Fabs: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.fab}>
      <Hidden down="md">
        <DesktopChangeFabs />
      </Hidden>
    </div>
  );
};
