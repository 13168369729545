import useMediaQuery from '@material-ui/core/useMediaQuery';
import { FunctionComponent } from 'react';

export interface HiddenProps {
  up?: string;
  down?: string;
}

export const Hidden: FunctionComponent<HiddenProps> = ({ up, down, children }) => {
  const hidden = useMediaQuery((theme) =>
    up ? (theme as any).breakpoints.up(up) : (theme as any).breakpoints.down(down)
  );

  if (up && down) {
    console.warn('up and down cannot both be set at the same time');
    return null;
  }

  return hidden ? null : <>{children}</>;
};
