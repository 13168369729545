import { FunctionComponent } from 'react';

import { LinkBlockDefinition, Styles } from '@brandlink/models';
import { Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { BLUE_STYLE } from '@brandlink/models';
import { isEmpty } from 'ramda';

export interface LinkBlockProps {
  block: LinkBlockDefinition;
  styles?: Styles;
  handleAnalyticsEvent?: () => void;
}

const useStyles = makeStyles<Theme, Styles>((theme) => ({
  link: ({ link }) => ({
    ...link?.main,
    transition: '0.3s',
    fontSize: '1rem',
    padding: '17px 8px',
    textAlign: 'center',
    '&:hover': {
      ...link?.hover,
    },
  }),
}));

const LinkBlock: FunctionComponent<LinkBlockProps> = ({ block, styles, handleAnalyticsEvent }) => {
  const classes = useStyles(isEmpty(styles ?? {}) ? BLUE_STYLE : styles!);

  if (!block.title || !block.url) return null;

  return (
    <Button
      onClick={handleAnalyticsEvent}
      className={classes.link}
      fullWidth
      href={block.url}
      target="_blank"
    >
      {block.title}
    </Button>
  );
};

export { LinkBlock };
