import { SocialsBlockDefinition } from '@brandlink/models';
import React, { FunctionComponent } from 'react';
import { SocialsTextField } from './SocialsTextField';
import { useBlockFromIndex, useEditor } from '../../../store';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { makeStyles } from '@material-ui/styles';
import {
  createDroppableIdFromBlockIndex,
  getStylesForDraggedItem,
  scaleTranslation,
} from '../../../utils/helpers';
import { Placeholder } from '../../DndEditor/Placeholder';

export interface SocialsBlockTextFieldContainerProps {
  blockIndex: number;
  inVisualEditor?: boolean;
}

const useStyles = makeStyles(() => ({
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const SocialsBlockTextFieldContainer: FunctionComponent<SocialsBlockTextFieldContainerProps> =
  ({ blockIndex, inVisualEditor }) => {
    const classes = useStyles();
    const scale = useEditor((editor) => editor.phoneScale);
    const block = useBlockFromIndex(blockIndex) as SocialsBlockDefinition;

    const droppableId = createDroppableIdFromBlockIndex('socials', blockIndex);

    return (
      <Droppable
        droppableId={droppableId}
        type="socials"
        renderClone={
          scale === 1 || !inVisualEditor
            ? undefined
            : (provided, snapshot, rubric) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                  <div
                    className={classes.inputContainer}
                    style={
                      inVisualEditor && snapshot.isDragging ? getStylesForDraggedItem(scale) : {}
                    }
                  >
                    <div {...provided.dragHandleProps}>
                      <DragIndicatorIcon />
                    </div>
                    <SocialsTextField
                      key={block.socials[rubric.source.index].name}
                      social={block.socials[rubric.source.index].name}
                      blockIndex={blockIndex}
                    />
                  </div>
                </div>
              )
        }
      >
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {block.socials.map((socials, index) => {
              return (
                <Draggable key={socials.name} draggableId={socials.name} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={
                        inVisualEditor
                          ? {
                              ...provided.draggableProps.style,
                              transform: scaleTranslation(
                                snapshot.isDragging,
                                provided.draggableProps.style?.transform,
                                scale
                              ),
                            }
                          : provided.draggableProps.style
                      }
                    >
                      <div className={classes.inputContainer}>
                        <div {...provided.dragHandleProps}>
                          <DragIndicatorIcon />
                        </div>
                        <SocialsTextField
                          key={socials.name}
                          social={socials.name}
                          blockIndex={blockIndex}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {inVisualEditor ? (
              <Placeholder droppableId={droppableId} placeholder={provided.placeholder} />
            ) : (
              provided.placeholder
            )}
          </div>
        )}
      </Droppable>
    );
  };
