import { makeStyles } from '@material-ui/styles';
import { FunctionComponent } from 'react';

const useStyles = makeStyles((theme) => ({
  separator: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.grey[600],
    margin: theme.spacing(3, 0),
    '&::before': {
      content: '""',
      flex: 1,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    '&::after': {
      content: '""',
      flex: 1,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    '&:not(:empty)::before': {
      marginRight: '.25em',
    },
    '&:not(:empty)::after': {
      marginLeft: '.25em',
    },
  },
}));

export const Separator: FunctionComponent = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.separator}>{children}</div>;
};
