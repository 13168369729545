import React, { FunctionComponent } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import {
  resetChanges,
  setIsSignupModalOpen,
  uploadChanges,
  useEditor,
  useEditorChanged,
  useShouldOpenSignupModal,
} from '../../../store';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import Fade from '@material-ui/core/Fade';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    position: 'absolute',
    top: 0,
    backgroundColor: '#fff',
    padding: '8px 0',
    zIndex: 1000,
  },
}));

export const MobileChangeButtons: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const uploading = useEditor((editor) => editor.uploading);
  const shouldOpenSignupModal = useShouldOpenSignupModal();
  const changed = useEditorChanged();

  if (!changed) {
    return null;
  }

  return (
    <Fade in={true}>
      <div className={classes.root}>
        <LoadingButton
          onClick={() => {
            if (shouldOpenSignupModal) {
              return dispatch(setIsSignupModalOpen(true));
            }
            dispatch(uploadChanges())
              .then(unwrapResult)
              .then((success) => {
                toast.success('Successfully saved', { autoClose: 2000 });
              })
              .catch((err) => {
                toast.error('Error saving');
              });
          }}
          loading={uploading}
          variant="contained"
          color="primary"
        >
          <SaveIcon sx={{ mr: 1 }} />
          Save Changes
        </LoadingButton>
        <LoadingButton
          loading={uploading}
          onClick={() => dispatch(resetChanges({}))}
          variant="contained"
          color="secondary"
        >
          <ClearIcon sx={{ mr: 1 }} />
          Reset Changes
        </LoadingButton>
      </div>
    </Fade>
  );
};
