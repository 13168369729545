import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Portal from '@material-ui/core/Portal';
import React, { FunctionComponent } from 'react';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { deleteBlock, setMousePos, setSelectedBlockIndex, useEditor } from '../../../store';

export const ContextMenu: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { selectedBlockIndex, mousePos } = useEditor(({ selectedBlockIndex, mousePos }) => ({
    selectedBlockIndex,
    mousePos,
  }));

  const handleClose = async () => {
    await dispatch(
      setMousePos({
        x: null,
        y: null,
      })
    );
  };

  const handleDelete = async () => {
    if (selectedBlockIndex !== undefined) await dispatch(deleteBlock(selectedBlockIndex));
    await dispatch(setSelectedBlockIndex(undefined));
    await handleClose();
  };

  return (
    <Portal container={document.body}>
      <Menu
        keepMounted
        open={mousePos.x !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mousePos.y !== null && mousePos.x !== null
            ? { top: mousePos.y, left: mousePos.x }
            : undefined
        }
      >
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </Portal>
  );
};
