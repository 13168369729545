import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import React, { FunctionComponent } from 'react';
import { setIsImageUploadModalOpen, useEditor } from '../../../store';
import { makeStyles } from '@material-ui/styles';
import defaultProfileImage from '../../../images/profile.png';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { useAppDispatch } from '../../../hooks/useAppStore';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 96,
    height: 96,
    '&:hover': {
      background: 'none',
      cursor: 'pointer',
    },
    '&:hover $icon': {
      display: 'block',
    },
  },
  badge: {
    cursor: 'pointer',
    marginTop: '20px',
    '&:hover $icon': {
      display: 'block',
    },
  },
  icon: {
    display: 'none',
    opacity: '0.5',
    fontSize: '30px',
    marginRight: '15px',
    marginBottom: '15px',
  },
}));

export const AvatarCard: FunctionComponent<any> = ({ setOpen }) => {
  const classes = useStyles();
  const user = useEditor((editor) => editor.user);
  const editedUserFields = useEditor((editor) => editor.editedUserFields);
  const dispatch = useAppDispatch();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pb: 1,
        color: 'white',
      }}
    >
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className={classes.badge}
        badgeContent={<AddCircleRoundedIcon className={classes.icon} />}
        onClick={() => dispatch(setIsImageUploadModalOpen(true))}
      >
        <Avatar
          className={classes.avatar}
          src={editedUserFields.avatar ?? (user.avatar || defaultProfileImage)}
        />
      </Badge>
      {(editedUserFields.title ?? (user.title || user.username)) && (
        <Typography variant="body3" component="p">
          {editedUserFields.title ?? (user.title || user.username)}
        </Typography>
      )}
      {editedUserFields.bio ||
        (user.bio && (
          <Typography variant="body3" component="p">
            {editedUserFields.bio ?? user.bio}
          </Typography>
        ))}
    </Box>
  );
};
