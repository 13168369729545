import { FunctionComponent } from 'react';
import './arrow.css';

const Arrow: FunctionComponent = () => {
  return (
    <div className="scroll-down">
      <div className="animate">More</div>
    </div>
  );
};

export { Arrow };
