import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { TabOptions } from '../../ui/TabOptions';
import { OverviewChart } from './OverviewChart';

const useStyles = makeStyles((theme) => ({
  card: { ...theme.card, maxWidth: undefined },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  overview: {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'center',
  },
  overviewItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
  },
  overviewItemInner: {
    display: 'flex',
    alignItems: 'center',
    '& h4': {
      paddingRight: '5px',
    },
  },
}));

export const AnalyticsPlaceholder: FunctionComponent = () => {
  const classes = useStyles();
  const [option, setOption] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setOption(newValue);
  };

  return (
    <Paper className={classes.card}>
      <div className={classes.header}>
        <Typography component="h2" variant="h5">
          Overview
        </Typography>
        <TabOptions
          value={option}
          handleChange={handleChange}
          options={['Week', 'Month', 'Year', 'Lifetime']}
        />
      </div>
      <div className={classes.overview}>
        Nothing here yet! Share your link to collect analytics and then return back
      </div>
      <div className={classes.overview}>
        <div className={classes.overviewItem}>
          <Typography variant="subtitle2">Total Views</Typography>
          <div className={classes.overviewItemInner}>
            <Typography variant="h4">0</Typography>
          </div>
        </div>
        <div className={classes.overviewItem}>
          <Typography variant="subtitle2">Total Clicks</Typography>
          <div className={classes.overviewItemInner}>
            <Typography variant="h4">0</Typography>
          </div>
        </div>
        <div className={classes.overviewItem}>
          <Typography variant="subtitle2">Click Through Rate</Typography>
          <div className={classes.overviewItemInner}>
            <Typography variant="h4">100%</Typography>
          </div>
        </div>
      </div>
      <OverviewChart
        labels={[]}
        dataSets={[
          { name: 'Clicks', values: [] },
          { name: 'Views', values: [] },
        ]}
      />
    </Paper>
  );
};
