import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  section: {
    padding: '75px 0',
  },
  greySection: {
    background: '#F7F9FC',
    position: 'relative',
  },
  callToAction: {
    textAlign: 'center',
  },
  copyright: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    padding: '20px 0',
    textAlign: 'center',
  },
}));

export const Footer: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <section className={clsx(classes.section, classes.greySection)}>
        <Container maxWidth="lg" className={classes.callToAction}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            NO SIGNUP NEEDED
          </Typography>
          <Typography variant="h3">Get started with Brandlink today</Typography>
          <Typography variant="h6">Share your brand and content with just one link</Typography>
          <Button variant="contained" component={Link} to="/demo" sx={{ mt: 3 }}>
            Try the demo
          </Button>
        </Container>
      </section>
      <section className={classes.copyright}>
        <Container maxWidth="lg">
          <Typography variant="body1">
            © 2021 Brandlink. All rights reserved. The best link on the planet!
          </Typography>
        </Container>
      </section>
    </div>
  );
};
