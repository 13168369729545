import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppStore } from '../../../hooks/useAppStore';
import { getUser, setIsSignupModalOpen, uploadChanges } from '../../../store/';
import { useDebouncedCallback } from 'use-debounce';
import { FunctionComponent, useState } from 'react';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { handleErrors } from '../../../utils/helpers';
import { GoogleLogin } from '../../ui/GoogleLogin';
import { Separator } from '../../ui/Separator';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(6, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
}));

export interface RegisterFormValues {
  email: string;
  username?: string;
  password: string;
}

export const SignupModal: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isSignupModalOpen = useAppStore((state) => state.editor.isSignupModalOpen);
  const [usernameError, setUsernameError] = useState<string | undefined>(undefined);

  const initialValues: RegisterFormValues = {
    email: '',
    password: '',
    username: '',
  };

  const handleClose = async () => {
    await dispatch(setIsSignupModalOpen(false));
  };

  const handleSubmit = async (values: RegisterFormValues) => {
    try {
      const response = await axios.post('/api/auth/register', values);
      if (response.status === 200) {
        await dispatch(getUser());
        history.push('/profile');
        await dispatch(uploadChanges());
        await handleClose();
      }
    } catch (err) {
      handleErrors(err, 'Error registering', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const validateUsername = useDebouncedCallback(async (username) => {
    try {
      await axios.get(`/api/users/${username}/exists`);
      setUsernameError(`The username "${username}" is taken`);
    } catch (err) {
      if (err.response.status === 404) {
        // success, username not available
        setUsernameError(undefined);
      }
    }
  }, 700);

  return (
    <Dialog open={isSignupModalOpen} onClose={handleClose} fullWidth maxWidth="md">
      <div className={classes.paper}>
        <Typography variant="body1" className={classes.title}>
          SIGN UP
        </Typography>
        <Typography component="h1" variant="h4">
          Save your work!
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Fill out the form to create an account
        </Typography>
        <GoogleLogin />
        <Separator>or</Separator>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleChange, isSubmitting }) => (
            <Form className={classes.form}>
              <Typography variant="subtitle2">Enter your email</Typography>
              <Field
                className={classes.textField}
                component={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email Address"
                name="email"
                autoComplete="email"
              />
              <Typography variant="subtitle2">Pick a username</Typography>
              <Field
                component={TextField}
                variant="outlined"
                margin="normal"
                fullWidth
                name="username"
                label="Username"
                type="text"
                error={!!usernameError}
                helperText={usernameError}
                onChange={(e) => {
                  handleChange(e);
                  setUsernameError(undefined);
                  validateUsername(e.target.value);
                  // reset errors
                }}
              />
              <Typography variant="subtitle2">Enter a password</Typography>
              <Field
                className={classes.textField}
                component={TextField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
              />
              <Grid container>
                <Grid item xs></Grid>
                <Grid item>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={isSubmitting}
                    className={classes.submit}
                    size="large"
                  >
                    Sign up
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};
