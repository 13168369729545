import Toolbar from '@material-ui/core/Toolbar';
import { FunctionComponent } from 'react';
import { Hidden } from '../components/ui/Hidden';
import { Navbar } from './Navbar';
import { Footer } from './Footer';

const Layout: FunctionComponent = ({ children }) => {
  return (
    <>
      <Navbar />
      <Toolbar /> {/* Used to push content downards */}
      <Hidden up="sm">
        <Toolbar />
      </Hidden>
      {children}
      <Footer />
    </>
  );
};

export default Layout;
