import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons/faArrowCircleUp';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons/faArrowCircleDown';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  percentage: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },
  container: {
    display: 'flex',
    color: (positive) => (positive ? '#56C796' : '#F92D2D'),
  },
  icon: {
    marginRight: '5px',
  },
}));

export interface PercentChangeProps {
  initial?: number;
  final?: number;
  change?: number;
}

export const PercentChange: FunctionComponent<PercentChangeProps> = ({
  initial,
  final,
  change,
}) => {
  let percent = 0;
  if (change) {
    percent = change;
  } else if (initial && final) {
    percent = (final - initial) / initial;
  }
  const classes = useStyles(percent > 0);

  if (!percent || Number.isNaN(percent)) return null;

  const icon = percent > 0 ? faArrowCircleUp : faArrowCircleDown;
  return (
    <div className={classes.container}>
      {percent && <FontAwesomeIcon className={classes.icon} icon={icon} />}
      <div className={classes.percentage}>{(percent * 100).toFixed(1)}%</div>
    </div>
  );
};
