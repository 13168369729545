import React, { FunctionComponent } from 'react';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  root: {},
}));

export const Affiliates: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="lg" sx={{ py: '150px' }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2">Affiliates</Typography>
          <Typography variant="h3">coming soon</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
