import { FunctionComponent } from 'react';
import { Box, CircularProgress, Container } from '@material-ui/core';

export const Api: FunctionComponent = () => {
  window.location.reload(); // reload to redirect to server using proxy

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    </Container>
  );
};
