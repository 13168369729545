import Button from '@material-ui/core/Button';
import { FunctionComponent } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import {
  reorderSocialsForSocialsBlock,
  setIsSocialsPickerModalOpen,
  setSelectedBlockIndex,
} from '../../../store';
import { useAppDispatch } from '../../../hooks/useAppStore';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { SocialsBlockTextFieldContainer } from './SocialsBlockTextFieldContainer';

export interface SocialsBlockEditorProps {
  blockIndex: number;
  inVisualEditor?: boolean;
}

export const SocialsBlockEditor: FunctionComponent<SocialsBlockEditorProps> = ({
  blockIndex,
  inVisualEditor,
}) => {
  const dispatch = useAppDispatch();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    dispatch(
      reorderSocialsForSocialsBlock({
        blockIndex,
        source: result.source.index,
        destination: result.destination.index,
      })
    );
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Button
          onClick={() => {
            dispatch(setSelectedBlockIndex(blockIndex));
            dispatch(setIsSocialsPickerModalOpen(true));
          }}
          variant="outlined"
          startIcon={<AddIcon />}
          sx={{ mr: 1 }}
        >
          Add Social
        </Button>
      </Box>
      {inVisualEditor ? (
        <SocialsBlockTextFieldContainer blockIndex={blockIndex} inVisualEditor />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <SocialsBlockTextFieldContainer blockIndex={blockIndex} />
        </DragDropContext>
      )}
    </>
  );
};
