import { AnalyticsData } from '@brandlink/models';
import dayjs from 'dayjs';
import { memoizeWith, path } from 'ramda';

export const UNIX_DAY_LENGTH = 86400;

export enum AnalyticsOptions {
  Week,
  Month,
  Year,
  Lifetime,
}

export const AnalyticsOptionsConfig = {
  [AnalyticsOptions.Week]: {
    duration: 7,
    dateFormat: 'ddd, MMM D',
  },
  [AnalyticsOptions.Month]: {
    duration: 31,
    dateFormat: 'ddd, MMM D',
  },
  [AnalyticsOptions.Year]: {
    duration: 365,
    dateFormat: 'M/D/YYYY',
  },
  [AnalyticsOptions.Lifetime]: {
    duration: 0,
    dateFormat: 'M/D/YYYY',
  },
};

export interface ChartData {
  labels: string[];
  values: number[];
}

export interface AnalyzedData {
  total: number;
  prevTotal: number;
  values: number[];
}

export interface AnalyzedSummary {
  viewValues: number[];
  clickValues: number[];
  labels: string[];
  totalViews: number;
  prevTotalViews: number;
  totalClicks: number;
  prevTotalClicks: number;
}

const getDaysSinceEpoch = () => dayjs().utc().startOf('day').unix() / UNIX_DAY_LENGTH;

export class AnalyticsUtils {
  static getAnalyzedSummary(analytics: AnalyticsData, option: AnalyticsOptions): AnalyzedSummary {
    const {
      total: totalClicks,
      prevTotal: prevTotalClicks,
      values: clickValues,
    } = this.getMemoziedAnalyzedData(analytics, `clicks.total`, option);
    const {
      total: totalViews,
      prevTotal: prevTotalViews,
      values: viewValues,
    } = this.getMemoziedAnalyzedData(analytics, `views`, option);

    const labels = this.getLabels(analytics, option);

    return {
      viewValues,
      clickValues,
      labels,
      totalViews,
      prevTotalViews,
      totalClicks,
      prevTotalClicks,
    };
  }

  static getMemoizedAnalyzedSummary = memoizeWith((_, option) => {
    return option.toString();
  }, AnalyticsUtils.getAnalyzedSummary);

  static getClickAnalyzedData(
    analytics: AnalyticsData,
    blockId: string,
    option: AnalyticsOptions
  ): AnalyzedData {
    return this.getMemoziedAnalyzedData(analytics, `clicks.${blockId}`, option);
  }

  static getAnalyzedData(
    analytics: AnalyticsData,
    pathTo: string,
    option: AnalyticsOptions
  ): AnalyzedData {
    const earliestViewDay = this.getEarliestViewDay(analytics);
    const startDay = this.getStartDay(earliestViewDay, option);
    const endDay = getDaysSinceEpoch();
    const prevDay = startDay - (AnalyticsOptionsConfig[option]?.duration || 7);
    const prevTotal = this.getTotalFromTimePeriod(analytics, pathTo, prevDay, startDay - 1);
    const total = this.getTotalFromTimePeriod(analytics, pathTo, startDay, endDay);
    const values = this.getValuesFromTimePeriod(analytics, pathTo, startDay, endDay);

    return {
      prevTotal,
      total,
      values,
    };
  }

  static getMemoziedAnalyzedData = memoizeWith((_, pathTo, option) => {
    return pathTo + option.toString();
  }, AnalyticsUtils.getAnalyzedData);

  static analyzeBlock(analytics: AnalyticsData, blockId: string, option: AnalyticsOptions) {
    return AnalyticsUtils.getMemoziedAnalyzedData(analytics, `clicks.${blockId}`, option);
  }

  static getTotalFromTimePeriod = (
    analytics: AnalyticsData,
    pathTo: string,
    startDay: number,
    endDay: number
  ): number => {
    let total = 0;

    for (let day = startDay; day <= endDay; day++) {
      total += path<number>([...pathTo.split('.'), day.toString()], analytics) ?? 0;
    }

    return total;
  };

  static getValuesFromTimePeriod = (
    analytics: AnalyticsData,
    pathTo: string,
    startDay: number,
    endDay: number
  ): number[] => {
    const result: number[] = [];

    for (let day = startDay; day <= endDay; day++) {
      result.push(path<number>([...pathTo.split('.'), day.toString()], analytics) ?? 0);
    }

    return result;
  };

  static getLabels(analytics: AnalyticsData, option: AnalyticsOptions) {
    const earliestViewDay = this.getEarliestViewDay(analytics);
    const startDay = this.getStartDay(earliestViewDay, option);
    const endDay = getDaysSinceEpoch();

    const labels: string[] = [];
    for (let day = startDay; day <= endDay; day++) {
      labels.push(
        dayjs
          .unix(day * UNIX_DAY_LENGTH)
          .utc()
          .format(AnalyticsOptionsConfig[option]?.dateFormat || 'M/D/YYYY')
      );
    }
    return labels;
  }

  static getEarliestViewDay(analytics: AnalyticsData): number {
    return Object.keys(analytics?.views ?? {}).reduce(
      (acc, unix) => Math.min(Number(unix), acc),
      Infinity
    );
  }

  static getStartDay(earliestViewDay: number, option: AnalyticsOptions) {
    return option === AnalyticsOptions.Lifetime
      ? earliestViewDay
      : Math.max(
          getDaysSinceEpoch() - (AnalyticsOptionsConfig[option]?.duration || 7),
          earliestViewDay
        );
  }
}
