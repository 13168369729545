import { FunctionComponent } from 'react';
import { DragStart } from 'react-beautiful-dnd';
import { setDestinationDroppableId, setPlaceHolderDimensions } from '../store';
import { useAppDispatch } from './useAppStore';

const queryAttr = 'data-rbd-draggable-id';

export type PlaceholderComponent = FunctionComponent<{ droppableId: string }>;

export const useDndPlaceholder = () => {
  const dispatch = useAppDispatch();

  // must be called in onDragEnd
  const resetPlaceholder = () => {
    dispatch(
      setPlaceHolderDimensions({
        height: 0,
        width: 0,
      })
    );
    dispatch(setDestinationDroppableId(undefined));
  };

  const onBeforeDragStart = (event: DragStart) => {
    const domQuery = `[${queryAttr}='${event.draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;

    dispatch(
      setPlaceHolderDimensions({
        height: clientHeight,
        width: clientWidth,
      })
    );
    dispatch(setDestinationDroppableId(event.source.droppableId));
  };

  return { onBeforeDragStart, resetPlaceholder };
};
