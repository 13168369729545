import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link, useRouteMatch } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import EditIcon from '@material-ui/icons/Edit';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    boxShadow: 'rgb(140 152 164 / 25%) 3px 3px 6px 3px',
  },
}));

export const AdminMobileTabsControl: FunctionComponent = () => {
  const classes = useStyles();

  const [value, setValue] = React.useState(1);

  let match = useRouteMatch();

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label="Visual"
        icon={<PhoneIphoneIcon />}
        component={Link}
        to={`${match.path}/visual-editor`}
      />
      <BottomNavigationAction
        label="Editor"
        icon={<EditIcon />}
        component={Link}
        to={`${match.path}/`}
      />
      <BottomNavigationAction
        label="Appearance"
        icon={<AccountBoxIcon />}
        component={Link}
        to={`${match.path}/appearance`}
      />
      <BottomNavigationAction
        label="Analytics"
        icon={<ShowChartIcon />}
        component={Link}
        to={`${match.path}/analytics`}
      />
    </BottomNavigation>
  );
};
